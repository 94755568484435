import { useAuth, formatDateToPrettyTime, useStyling, ShiftCard, useFetch } from "shiftly-ui";
import styles from "./MessagesBubble.module.css";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { useNavigate } from "react-router-dom";
const MessagesBubble = ({ content, for_id, sent_date, after, type = "text" }) => {
  const { user } = useAuth();
  const { activeLocation } = useShiftlyLocation();
  const forMe = user._id === for_id || activeLocation?._id === for_id;
  const showTime = !after || new Date(after.sent_date).getTime() - new Date(sent_date).getTime() > 300000;
  const styling = useStyling(styles);
  const navigate = useNavigate();
  const {
    data: [shift],
  } = useFetch({
    request: {
      entity: "Shift",
      method: "get",
      populate: [
        "position",
        {
          path: "location",
          populate: "address",
        },
      ],
      criteria: {
        _id: content,
      },
    },
    dependency: content,
    options: {
      enabled: type === "shift",
      select: (data) => {
        return data.map((s) => ({ ...s, address: s.location?.address }));
      },
    },
  });
  return (
    <div className={styling("container")}>
      {type === "text" && (
        <div className={styling("bubble", forMe ? "receiving" : "sending")}>
          <p className={styling("text")}>{content}</p>
        </div>
      )}
      {type === "shift" && (
        <div
          className={styling("shift-container", forMe ? "left" : "right")}
          onClick={() => navigate(`/people?shift=${shift?._id}`)}
        >
          <ShiftCard shift={shift} />
        </div>
      )}
      {showTime && (
        <p className={styling("sent_time", forMe ? "left" : "right")}>{formatDateToPrettyTime(sent_date)}</p>
      )}
    </div>
  );
};
export default MessagesBubble;
