import { Input, useStyling } from "shiftly-ui";
import { useCallback, useEffect, useRef } from "react";
import styles from "./NewPosition.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons";
const NewPositionQualification = ({ id, description, setQualifications, index, qualifications }) => {
  const styling = useStyling(styles);
  const inputRef = useRef();
  useEffect(() => {
    if (!description && index === qualifications.length - 1) {
      inputRef.current.focus();
    }
  }, [inputRef, description, index, qualifications]);
  const removeQualification = useCallback(() => {
    setQualifications((prev) => prev.filter((qualification, i) => qualification.id !== id));
  }, [id, setQualifications]);
  const updateQualification = useCallback(
    (field, value) => {
      setQualifications((prev) =>
        prev.map((qualification) => {
          if (qualification.id === id) {
            return { ...qualification, [field]: value };
          }
          return qualification;
        })
      );
    },
    [setQualifications, id]
  );
  return (
    <div className={styling("qualification-container")}>
      <Input
        placeholder={"RSA / Customer Service / Barista Trained"}
        onChange={(v) => updateQualification("description", v)}
        value={description}
        ref={inputRef}
        label={"Qualification"}
      />
      <div className={styling("location-trash")} onClick={removeQualification}>
        <FontAwesomeIcon icon={faTrash} />
      </div>
    </div>
  );
};
export default NewPositionQualification;