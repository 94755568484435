import { useStyling, useFetch, Each, ShiftCard } from "shiftly-ui";
import styles from "./MessagesShiftTray.module.css";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const MessagesShiftTray = ({ activeConversation, open, setOpen, sendMessage }) => {
  const styling = useStyling(styles);
  const { activeLocation } = useShiftlyLocation();
  const { data: upcomingShifts } = useFetch({
    request: {
      entity: "Shift",
      method: "get",
      populate: [
        "position",
        {
          path: "location",
          populate: ["address"],
        },
      ],
      criteria: {
        status: {
          $nin: ["cancelled", "expired", "draft", "completed"],
        },
        location: activeLocation?._id,
      },
    },
  });
  const handleSendShift = (shift) => {
    const user_id = activeConversation.user?._id || activeConversation.user;
    const location_id = activeConversation.location?._id || activeConversation.location;
    const newMessage = {
      content: shift,
      conversation: activeConversation._id,
      user: user_id,
      location: location_id,
      for_id: user_id,
      unread: true,
      sent_date: new Date(),
      type: "shift",
    };
    sendMessage({
      entity: "Message",
      data: newMessage,
      method: "create",
    });
  };
  return (
    <>
      <div className={styling("container", open && "open")}>
        <div className={styling("title")}>
          <h4>Upcoming Shifts</h4>
          <p>Select a shift to reference in chat:</p>
        </div>
        <div className={styling("shifts")}>
          <Each
            of={upcomingShifts}
            render={(shift, index) => (
              <div
                key={index}
                className={styling("shift-card")}
                onClick={() => {
                  setOpen(false);
                  handleSendShift(shift?._id);
                }}
              >
                <ShiftCard shift={shift} />
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};
export default MessagesShiftTray;
