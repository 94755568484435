import { businessActions, useFetch } from "shiftly-ui";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const useBusiness = () => {
  const business = useSelector((state) => state.business);
  const ui = useSelector((state) => state.ui);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: availableBusinesses,
    refetch: refreshAvailableBusinesses,
    isLoading,
  } = useFetch({
    request: {
      entity: "Business",
      method: "get",
      populate: ["industry"],
      id: "availableBusinesses",
    },
    onDataChange: (data) => {
      if (!business.activeBusiness) {
        setActiveBusiness(data[0]);
      }
    },
    options: {
      enabled: ui?.settings?.mode === "owner",
    },
  });
  const setActiveBusiness = useCallback(
    (business) => {
      if (!business) return;
      dispatch(businessActions.setActiveBusiness(business));
      localStorage.setItem("activeBusiness", JSON.stringify(business));
    },
    [dispatch]
  );
  const initBusiness = useCallback(async () => {
    let activeBusiness;
    let activeLocation;
    let parsedBusiness;
    let parsedLocation;
    const storedBusiness = localStorage.getItem("activeBusiness");
    const storedLocation = localStorage.getItem("activeLocation");
    if (storedBusiness) {
      parsedBusiness = JSON.parse(storedBusiness);
      if (parsedBusiness.account_id === user?.account_id?._id) {
        activeBusiness = parsedBusiness;
        dispatch(businessActions.setActiveBusiness(activeBusiness));
      }
    }
    if (storedLocation) {
      parsedLocation = JSON.parse(storedLocation);
      if (parsedLocation.account_id === user?.account_id?._id) {
        activeLocation = parsedLocation;
        dispatch(businessActions.setActiveLocation(activeLocation));
      }
    }
    if (activeBusiness?._id === undefined) return;
    if (
      parsedBusiness &&
      parsedLocation &&
      activeBusiness?.account_id === user?.account_id?._id &&
      activeLocation?.account_id === user?.account_id?._id
    ) {
      const pathname = window.location.pathname;
      if (pathname === "/") {
        navigate("/shifts");
      }
    }
  }, [dispatch, user, navigate]);
  return useMemo(
    () => ({
      ...business,
      availableBusinesses,
      refreshAvailableBusinesses,
      initBusiness,
      setActiveBusiness,
      isLoading,
    }),
    [business, refreshAvailableBusinesses, initBusiness, setActiveBusiness, isLoading, availableBusinesses]
  );
};
export default useBusiness;
