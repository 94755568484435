import { useFetch } from "shiftly-ui";
import { useMemo } from "react";
import useBusiness from "./useBusiness";
const useIntegration = (type) => {
  const { activeBusiness } = useBusiness();
  const {
    data: [activeIntegration],
    isLoading,
    isFetching,
    refetch,
  } = useFetch({
    request: {
      entity: "Integration",
      criteria: {
        business: activeBusiness?._id,
        type,
      },
      id: "Integration.GetActiveIntegrationByType",
    },
    dependency: activeBusiness?._id,
  });
  const { data: locationMap } = useFetch({
    request: {
      entity: "Location",
      method: "get",
      criteria: {
        _id: { $in: Object.values(activeIntegration?.locationMap || {}) },
      },
    },
    dependency: activeIntegration?.locationMap,
    options: {
      select: (data) => {
        const map = {};
        data.forEach((loc) => {
          const id = Object.entries(activeIntegration?.locationMap || {}).find(
            ([key, value]) => value === loc._id
          )?.[0];
          if (id) {
            map[id] = loc;
          }
        });
        return map;
      },
    },
  });
  const { data: positionMap } = useFetch({
    request: {
      entity: "Position",
      method: "get",
      criteria: {
        _id: { $in: Object.values(activeIntegration?.positionMap || {}) },
      },
    },
    dependency: activeIntegration,
    options: {
      select: (data) => {
        const map = {};
        data.forEach((pos) => {
          const id = Object.entries(activeIntegration?.positionMap || {}).find(
            ([key, value]) => value === pos._id
          )?.[0];
          if (id) {
            map[id] = pos;
          }
        });
        return map;
      },
    },
  });
  return useMemo(
    () => ({ isLoading, refetch, activeIntegration, locationMap, positionMap, isFetching }),
    [isLoading, refetch, activeIntegration, locationMap, positionMap, isFetching]
  );
};
export default useIntegration;