import styles from "./MobileRating.module.css";
import { useStyling, MobileStepper, SlideFrom, useProfile, TextArea, RatingSlider } from "shiftly-ui";
import { useMemo } from "react";
import useRating from "./useRating";

const MobileRating = ({ open, setOpen, shifts }) => {
  const styling = useStyling(styles);

  const { ratings, comments, setRatings, setComments, handleSave } = useRating({
    shifts,
    setOpen,
  });

  const steps = useMemo(
    () =>
      shifts.map((shift) => ({
        label: shift.user.name,
        key: shift._id,
        content: (
          <RateAction
            user={shift.user}
            rating={ratings[shift._id] || 0}
            setRating={(value) => setRatings((prev) => ({ ...prev, [shift._id]: value }))}
            comment={comments[shift._id] || ""}
            setComment={(value) => setComments((prev) => ({ ...prev, [shift._id]: value }))}
          />
        ),
      })),
    [shifts, ratings, comments, setRatings, setComments]
  );

  return (
    <SlideFrom open={open} setOpen={setOpen} className={styling("slide-container", open && "show")}>
      <MobileStepper showNav={false} steps={steps} onNext={handleSave} onComplete={handleSave}></MobileStepper>;
    </SlideFrom>
  );
};

const RateAction = ({ user, rating, setRating, comment, setComment }) => {
  const styling = useStyling(styles);
  const { profile } = useProfile({ criteria: { user: user._id } });

  return (
    <div className={styling("container")}>
      <div className={styling("profile-picture")}>
        <img src={profile?.profile_picture ?? "/defaults/shifter.png"} alt="Shifter" />
      </div>

      <div className={styling("title")}>
        <h4>
          Give {user?.first_name} {user?.last_name} a <span>rating</span>
        </h4>
      </div>
      <div className={styling("stars-container")}>
        <RatingSlider value={rating} setValue={setRating} />
      </div>
      <div className={styling("rating-text-review")}>
        <TextArea
          value={comment}
          setValue={setComment}
          label={"Leave a review"}
          placeholder={`Write something nice about ${user?.first_name}...`}
        />
      </div>
    </div>
  );
};

export default MobileRating;
