import { useFetch, Each, useStyling } from "shiftly-ui";
import { useEffect } from "react";
import styles from "./MessagesChat.module.css";
import MessageBubble from "./MessagesBubble";
import useMessagesCriteria from "src/pages/app/messages/hooks/useMessagesCriteria";
import useReadMessages from "./hooks/useReadMessages";
const MessagesChat = ({ activeConversation, scrollToBottom }) => {
  const criteria = useMessagesCriteria(activeConversation);
  const styling = useStyling(styles);
  const readMessage = useReadMessages(activeConversation);
  const { data: messages } = useFetch({
    request: {
      entity: "Message",
      method: "getMessagesWithAccess",
      data: {
        conversation_id: activeConversation?._id,
        ...criteria,
      },
      id: "Message.GetMessagesWithAccess",
    },
    options: {
      enabled: Boolean(activeConversation?._id),
    },
    dependency: { conversation_id: activeConversation?._id, criteria },
  });
  useEffect(() => {
    const id = setTimeout(() => {
      if (!activeConversation._id) return;
      readMessage(activeConversation._id);
    }, 200);
    return () => {
      clearTimeout(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation, messages]);
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);
  return (
    <div className={styling("container")}>
      {messages?.length ? (
        <Each
          of={messages}
          render={({ key, ...message }, index) => (
            <MessageBubble key={key} {...message} after={messages[index + 1]} before={messages[index - 1]} />
          )}
        />
      ) : (
        <h2 className={styling("no-messages-text")}>
          You have no messages with {activeConversation?.user?.first_name}.
        </h2>
      )}
    </div>
  );
};
export default MessagesChat;