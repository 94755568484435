import { Toggle } from "shiftly-ui";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import styles from "./MobileLocationSelector.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRepeat, faGear, faLocationDot, faCalendar, faUserCheck } from "@fortawesome/pro-regular-svg-icons";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import { useMemo, useState } from "react";
const MobileLocationSelector = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { activeLocation } = useShiftlyLocation();
  const [tab, setTab] = useState(0);
  const path = window.location.pathname;
  const tabs = useMemo(
    () => [
      { text: "Today", icon: <FontAwesomeIcon icon={faCalendar} /> },
      { text: "Upcoming", icon: <FontAwesomeIcon icon={faUserCheck} /> },
    ],
    []
  );
  if (pathname.includes("business-account")) return null;
  return (
    <div className={styles["container"]}>
      <div className={styles["searchbox-container"]}>
        <div className={styles["searchbox"]} onClick={() => navigate("/business-account")}>
          <div className={clsx("XXXShidden", styles["search-icon"])}>
            <FontAwesomeIcon icon={faLocationDot} />
          </div>
          <div className={styles["search-content"]}>
            <p className={clsx(styles["filter-header"])}>{activeLocation?.name}</p>
            <p className={clsx("small", styles["filter-addnotes"])}>
              tap to switch locations{" "}
              <span>
                <FontAwesomeIcon icon={faArrowsRepeat} />
              </span>
            </p>
          </div>
          <div className={styles["filterbox"]}>
            <FontAwesomeIcon icon={faGear} />
          </div>
        </div>
      </div>
      {path === "/mobile-shifts" && <Toggle tab={tab} setTab={setTab} tabs={tabs} />}
    </div>
  );
};
export default MobileLocationSelector;