import { Stepper, useFetch, useToast, useStyling, generalMessages, ModalLabel, FormV2 } from "shiftly-ui";
import styles from "./NewPosition.module.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import NewPositionDetails from "./NewPositionDetails";
import NewPositionQualifications from "./NewPositionQualifications";
import NewPositionWage from "./NewPositionWage";
import NewPositionPreview from "./NewPositionPreview";
import useBusiness from "src/hooks/useBusiness";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import usePositions from "src/hooks/usePositions";
const NewPositionModal = ({ newPositionModal, setNewPositionModal, initial }) => {
  const [qualifications, setQualifications] = useState([]);
  const [formData, setFormData] = useState({});
  const formRef = useRef();
  useEffect(() => {
    setFormData(initial);
  }, [initial]);
  const { activeBusiness } = useBusiness();
  const { activeLocation, refreshAvailableLocations } = useShiftlyLocation();
  const { refreshAvailablePositions } = usePositions();
  const toast = useToast();
  const styling = useStyling(styles);
  useEffect(() => {
    if (!newPositionModal) return;
    setQualifications(initial.qualifications || []);
  }, [newPositionModal, initial]);
  const {
    post: postPosition,
    isLoading,
    refresh,
  } = useFetch({
    options: {
      onSuccess: () => {
        refreshAvailableLocations();
        setNewPositionModal(false);
        refreshAvailablePositions();
        refresh("Position.GetPositionsByBusiness");
        setTimeout(() => {
          formRef.current.reset();
          setQualifications([]);
        }, 500);
      },
    },
  });
  const steps = useMemo(
    () => [
      {
        label: "Position Details",
        content: <NewPositionDetails data={formData} setData={setFormData} />,
        validation: async () => await formRef.current.validateFields(["description", "group"]),
      },
      {
        label: "Wage",
        content: <NewPositionWage />,
        validation: async () => await formRef.current.validateFields(["position_rate_modifier"]),
      },
      {
        label: "Qualifications",
        content: <NewPositionQualifications qualifications={qualifications} setQualifications={setQualifications} />,
      },
      {
        label: "Preview",
        content: <NewPositionPreview qualifications={qualifications} data={formData} />,
      },
    ],
    [qualifications, formData]
  );
  const handleSubmit = useCallback(async () => {
    try {
      const { group, description, position_rate_modifier } = formData;
      if (!group || !description || position_rate_modifier < 0) {
        throw new Error(generalMessages.form_validation_error.prettyError);
      }
      const position = {
        ...formData,
        qualifications: qualifications.flatMap((q) => (q.description ? q : [])),
        position_rate_modifier,
        business: activeBusiness._id,
        location: activeLocation._id,
        award_code: activeBusiness?.industry?.award_code,
      };
      postPosition({
        entity: "Position",
        method: position?._id ? "update" : "create",
        data: position,
        criteria: { _id: position?._id },
      });
    } catch ({ message }) {
      toast.error(message, "Error Creating Position");
    }
  }, [qualifications, postPosition, activeBusiness, activeLocation, toast, formData]);
  return (
    <FormV2 data={formData} setData={setFormData} ref={formRef} onSubmit={handleSubmit}>
      <Stepper
        label={<ModalLabel text="Positions" />}
        steps={steps}
        className={styling("modal")}
        showStepper={newPositionModal}
        setShowStepper={setNewPositionModal}
        onComplete={handleSubmit}
        loading={isLoading}
        keyDown={false}
      />
    </FormV2>
  );
};
export default NewPositionModal;