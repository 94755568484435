import { useUI, useFetch, withDisplay } from "shiftly-ui";
import {
  faCalendarWeek,
  faClock,
  faEnvelope,
  faGear,
  faUserGroup,
  faUserPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import BusinessNavLaptopMode from "./components/BusinessNavLaptopMode";
import BusinessNavTabletMode from "./components/BusinessNavTabletMode";
import BusinessNavMobileMode from "./components/BusinessNavMobileMode";
import useMessagesCriteria from "src/pages/app/messages/hooks/useMessagesCriteria";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const excludeNavItemsPaths = [
  "/",
  "/business-account",
  "/business-account/billing-and-payment",
  "/business-account/billing-and-payment/payment-history",
  "/business-account/business-details",
  "/business-account/locations/edit-location",
  "/business-account/locations",
  "/integrations",
  "/integrations/deputy",
];
const BusinessNavigation = ({ isLaptop, isMobile, isTablet }) => {
  const { setActiveLocation, activeLocation } = useShiftlyLocation();
  const [visible, setVisible] = useState(true);
  const { excludedPaths } = useUI();
  const location = useLocation();
  const [showLocationSelector, setShowLocationSelector] = useState(true);
  const [locationFilter, setlocationFilter] = useState(activeLocation?._id);
  const criteria = useMessagesCriteria();
  const { data: unreadMessages } = useFetch({
    request: {
      entity: "Message",
      method: "getMessagesWithAccess",
      data: { ...criteria },
      id: "Message.GetMessagesWithAccess",
    },
    options: {
      enabled: Boolean(activeLocation?._id),
      staleTime: 0,
      select: (data) => data.filter((message) => message.unread && message.for_id === activeLocation?._id),
    },
  });
  const links = useMemo(() => {
    if (!showLocationSelector && isLaptop) {
      return [];
    }
    const links = [
      {
        label: "People",
        icon: faUserPlus,
        path: "/people",
        activeKey: "people",
      },
      {
        label: "Messaging",
        icon: faEnvelope,
        path: "/messaging",
        activeKey: "messaging",
        badge: unreadMessages.length,
      },
    ];
    if (isLaptop || isTablet) {
      links.unshift({
        label: "Shifts",
        icon: faCalendarWeek,
        path: "/shifts",
        activeKey: "shifts",
      });
      links.push({
        label: "Positions",
        icon: faUserGroup,
        path: "/positions",
      });
    }
    if (isMobile) {
      links.unshift({
        label: "Shifts",
        icon: faClock,
        activeKey: "mobile-shifts",
        path: "/mobile-shifts",
      });
      links.push({
        label: "Account",
        icon: faGear,
        path: "/business-account",
        activeKey: "business-account",
      });
    }
    return links;
  }, [showLocationSelector, isLaptop, isMobile, unreadMessages, isTablet]);
  const {
    data: [updatedLocation],
  } = useFetch({
    request: { entity: "Location", method: "get", criteria: { _id: locationFilter } },
    dependency: locationFilter,
  });
  useEffect(() => {
    updatedLocation && setActiveLocation(updatedLocation);
  }, [updatedLocation, setActiveLocation]);
  useEffect(() => {
    const path = window.location.pathname;
    setVisible(!excludedPaths.includes(path));
    setShowLocationSelector(!excludeNavItemsPaths.includes(path));
  }, [excludedPaths, location, links]);
  useEffect(() => {
    setlocationFilter(activeLocation?._id);
  }, [activeLocation]);
  switch (true) {
    case !visible:
      return <></>;
    case isLaptop:
      return (
        <BusinessNavLaptopMode
          links={links}
          locationFilter={locationFilter}
          setlocationFilter={setlocationFilter}
          showLocationSelector={showLocationSelector}
        />
      );
    case isTablet:
      return (
        <BusinessNavTabletMode
          links={links}
          locationFilter={locationFilter}
          setlocationFilter={setlocationFilter}
          showLocationSelector={showLocationSelector}
        />
      );
    case isMobile:
      return <BusinessNavMobileMode links={links} />;
    default:
      return null;
  }
};
export default withDisplay(BusinessNavigation);