import { Button, withDisplay, useAuth, FormV2, Link, Input } from "shiftly-ui";
import styles from "./Portal.module.css";
import { useCallback } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
const Login = ({ isLaptop, isMobile }) => {
  const { login, loginIsLoading: isLoading } = useAuth();
  const navigate = useNavigate();
  const onSubmit = useCallback(
    async (data) => {
      return login(data, "owner");
    },
    [login]
  );
  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={styles["container"]}
    >
      <div className={styles["content"]}>
        <div className={styles["heading"]}>
          <h2>
            Welcome <span>Back!</span>
          </h2>
          <p>Log in to access shiftly and take the next step to finding flexible staff!</p>
        </div>
        <div className={styles["form"]}>
          <FormV2 onSubmit={onSubmit}>
            <Input name={"email"} />
            <Input name={"password"} submitOnEnter validate={false} />
            <div className={styles["forgot-password"]}>
              <Link onClick={() => navigate("/portal?page=reset")}>Forgotten your password?</Link>
            </div>
            <div
              className={styles["buttons-container"]}
              style={{
                justifyContent: isMobile ? "space-between" : "center",
              }}
            >
              {isMobile && (
                <Button theme={"secondary-outline"} onClick={() => navigate(process.env.REACT_APP_SHIFTER_APP_URL)}>
                  I'm looking for work
                </Button>
              )}
              <Button theme={"primary"} type="submit" loading={isLoading}>
                Login
              </Button>
            </div>
            {isLaptop && (
              <div className={styles["signup"]}>
                <p>Are you new here?</p>
                <span>
                  <Link onClick={() => navigate("/portal?page=signup")}>Create an account</Link>
                </span>
              </div>
            )}
          </FormV2>
        </div>
      </div>
    </motion.div>
  );
};
export default withDisplay(Login);