import { useFetch, responsibilities } from "shiftly-ui";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

const levels = ["Level 1", "Level 2", "Level 3", "Level 4", "Level 5", "Level 6", "Level 7"];

const usePositions = () => {
  const business = useSelector((state) => state.business);
  const activeLocation = useMemo(() => business.activeLocation, [business]);
  const activeBusiness = useMemo(() => business.activeBusiness, [business]);

  const { data: allGroups } = useFetch({
    request: {
      entity: "PositionGroup",
      method: "get",
      criteria: { award: activeBusiness?.industry?.award_code },
    },
    dependency: activeBusiness?.industry?.award_code,
    options: {
      select: (data) => {
        const award_code = activeBusiness?.industry?.award_code;
        return data.filter((group) => {
          return responsibilities[award_code]?.[group.stream];
        });
      },
    },
  });

  const {
    data: allPositions,
    refetch: refreshAvailablePositions,
    isLoading,
  } = useFetch({
    request: {
      entity: "Position",
      method: "get",
      populate: ["group"],
      criteria: { business: business?.activeBusiness?._id },
      id: "Position.GetPositionsByBusiness",
    },
    dependency: business?.activeBusiness?._id,
    options: {
      select: (data) => {
        const award_code = activeBusiness?.industry?.award_code;
        return data.flatMap((position) => {
          const group = allGroups.find((g) => g._id === position.group?._id);
          if (!group) return [];
          const responsibilitiesForPosition =
            responsibilities[award_code]?.[group.stream]?.[position.classification_level];
          if (!responsibilitiesForPosition) return [];
          return {
            ...position,
            group,
            responsibilities: responsibilitiesForPosition || [],
          };
        }, []);
      },
    },
  });

  const availablePositions = useMemo(
    () => allPositions.filter((p) => p.location === activeLocation?._id),
    [activeLocation, allPositions]
  );

  const availablePositionsSummary = useMemo(() => {
    const map = availablePositions.reduce((acc, position) => {
      if (!acc[position.name]) {
        acc[position.name] = position;
      }
      return acc;
    }, {});
    return Object.values(map);
  }, [availablePositions]);

  const groupedPositions = useMemo(() => {
    const grouped = {};
    availablePositions.forEach((position) => {
      if (!grouped[position.group._id]) {
        grouped[position.group._id] = [];
      }
      grouped[position.group._id].push(position);
    });
    return grouped;
  }, [availablePositions]);

  const dropdownPositions = useMemo(() => {
    return availablePositions.reduce((acc, position) => {
      const group = acc.find((g) => g.value === position.group._id);
      if (!group) {
        acc.push({
          value: position.group?._id,
          label: position.name,
        });
      }
      return acc;
    }, []);
  }, [availablePositions]);

  const getAvailableLevels = useCallback(
    (group) => {
      if (!group) return [];
      const positions = availablePositions.filter((p) => p.group?._id === group);
      const levelsInGroup = positions.reduce((acc, position) => {
        if (!acc.find((l) => l.value === position.classification_level)) {
          acc.push({
            value: position.classification_level,
            label: levels[position.classification_level - 1],
          });
        }
        return acc;
      }, []);
      return levelsInGroup.sort((a, b) => a.value - b.value);
    },
    [availablePositions]
  );

  const getPositionFromLevelAndGroup = useCallback(
    (group, level) => {
      return availablePositions.find((p) => p.group?._id === group && p.classification_level === level);
    },
    [availablePositions]
  );

  return useMemo(
    () => ({
      availablePositions,
      availablePositionsSummary,
      allPositions,
      allGroups,
      isLoading,
      refreshAvailablePositions,
      groupedPositions,
      dropdownPositions,
      getAvailableLevels,
      getPositionFromLevelAndGroup,
      levels,
    }),
    [
      allPositions,
      availablePositions,
      availablePositionsSummary,
      allGroups,
      isLoading,
      refreshAvailablePositions,
      groupedPositions,
      dropdownPositions,
      getAvailableLevels,
      getPositionFromLevelAndGroup,
    ]
  );
};
export default usePositions;
