import { useFetch, roundNumber, titleCase, useStyling } from "shiftly-ui";
import StandardLayout from "../../settings/components/StandardLayout";
import styles from "./BusinessAccountPaymentHistory.module.css";
import clsx from "clsx";
import Illustration from "shiftly-ui/assets/svg/Illustrations/NoShifts.svg";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const BusinessAccountPaymentHistory = () => {
  const { activeLocation } = useShiftlyLocation();
  const styling = useStyling(styles);
  const { data: payments } = useFetch({
    request: {
      entity: "Payment",
      method: "get",
      criteria: {
        location: activeLocation?._id,
      },
      populate: [
        {
          path: "shift",
          populate: ["user", "position"],
        },
      ],
      dependency: activeLocation?._id,
    },
  });
  return (
    <StandardLayout
      heading={
        <h1>
          Payment <span>History</span>
        </h1>
      }
      breadcrumb={"Business Settings / Billing & Payment /"}
      returnLink={"/business-account/billing-and-payment"}
      description={<p style={{ fontWeight: "500" }}>Review your past payments or pending payments.</p>}
      size="large"
    >
      {payments.length ? (
        <table className={styles["table"]}>
          <thead>
            <tr>
              <th>Payment Status</th>
              <th>Payment Date</th>
              <th>Shift Position</th>
              <th>Shift Date</th>
              <th>Shifter Name</th>
              <th>Total Amount</th>
            </tr>
          </thead>
          <tbody>
            {payments?.map((payment) => (
              <tr key={payment._id}>
                <td>
                  <p className={clsx(styles["status"], styles[payment.status])}>{titleCase(payment.status)}</p>
                </td>
                <td>{new Date(payment.createdAt).toLocaleDateString()}</td>
                <td>{payment.shift.position?.name}</td>
                <td>{new Date(payment.shift.date).toLocaleDateString()}</td>
                <td>
                  {payment?.shift?.user?.first_name} {payment?.shift?.user?.last_name}
                </td>
                <td>${roundNumber(payment.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={styling("no-payment")}>
          <div className={styling("no-image")}>
            <img src={Illustration} alt="no payments" />
          </div>
          <p>You haven't made any payments yet. Accept an applicant to view payment here!</p>
        </div>
      )}
    </StandardLayout>
  );
};
export default BusinessAccountPaymentHistory;
