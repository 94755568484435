import { Button, withDisplay, useStyling, useNavigation as useNav } from "shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./StandardLayout.module.css";
import { faChevronLeft, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const StandardLayout = ({
  isMobile,
  heading,
  breadcrumb,
  description,
  returnLink,
  children,
  buttonText,
  buttonEvent = () => {},
  buttonProps = {},
  size = "small",
  showBackButton = true,
}) => {
  const navigate = useNavigate();
  const { goBack, pageHistory } = useNav();
  const styling = useStyling(styles);
  const hasBanner = (breadcrumb || buttonText) && !isMobile;
  return (
    <div className={styling("container")}>
      {hasBanner && (
        <div className={styling("banner")}>
          {breadcrumb && showBackButton && pageHistory.length > 1 && (
            <div className={styling("return")} onClick={() => goBack()}>
              <div className={styling("chevron-container")}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
              <p>Go Back</p>
            </div>
          )}
          {buttonText && (
            <Button
              ml={"auto"}
              icon={faPlus}
              iconSide="right"
              theme={"secondary"}
              onClick={buttonEvent}
              {...buttonProps}
            >
              {buttonText}
            </Button>
          )}
        </div>
      )}
      <div className={styling("heading-container")}>
        <div className={styling("breadcrumb")}>
          <h3 onClick={() => navigate(returnLink)}>{breadcrumb}</h3>
        </div>
        <div className={styling("title")}>{heading}</div>
        <div className={styling("description")}>{description}</div>
      </div>
      <div className={styling("content-container", size)}>{children}</div>
    </div>
  );
};
export default withDisplay(StandardLayout);