import { Input } from "shiftly-ui";
import styles from "./HQNewBusinessSetup.module.css";
import clsx from "clsx";
const NewBusinessDetails = () => {
  return (
    <div className={styles["container"]}>
      <div className={styles["heading"]}>
        <h1>
          Tell us a bit about <span>your business</span>
        </h1>
      </div>
      <div className={clsx(styles["content"], styles["short-content"])}>
        <Input name={"name"} label={"What is your business name"} placeholder={"Cafe on the Corner"} required />
      </div>
    </div>
  );
};
export default NewBusinessDetails;