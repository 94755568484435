import { useMemo } from "react";
import styles from "./DesktopRating.module.css";
import { Stepper, TextArea, useStyling, ModalLabel, useProfile, RatingSlider } from "shiftly-ui";
import useRating from "./useRating";

const DesktopRating = ({ open, setOpen, shifts }) => {
  const styling = useStyling(styles);

  const { activeStep, ratings, comments, setRatings, setComments, setActiveStep, handleSave } = useRating({
    shifts,
    setOpen,
  });
  const steps = useMemo(
    () =>
      shifts.map((shift) => ({
        label: shift.user.name,
        key: shift._id,
        content: (
          <RateAction
            user={shift.user}
            rating={ratings[shift._id] || 0}
            setRating={(value) => setRatings((prev) => ({ ...prev, [shift._id]: value }))}
            comment={comments[shift._id] || ""}
            setComment={(value) => setComments((prev) => ({ ...prev, [shift._id]: value }))}
          />
        ),
      })),
    [shifts, ratings, comments, setRatings, setComments]
  );

  return (
    <Stepper
      label={<ModalLabel text={`Rate your shifter${shifts.length > 1 ? "s" : ""}`} />}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      steps={steps}
      className={styling("stepper")}
      showStepper={open}
      setShowStepper={setOpen}
      onComplete={handleSave}
      onNext={handleSave}
      keydown={false}
    />
  );
};

const RateAction = ({ user, rating, setRating, comment, setComment }) => {
  const styling = useStyling(styles);
  const { profile } = useProfile({ criteria: { user: user._id } });

  return (
    <div className={styling("rate-container")}>
      <div className={styling("rate-picture-container")}>
        <div className={styling("rate-picture")}>
          <img src={profile?.profile_picture ?? "/defaults/shifter.png"} alt="Profile" />
        </div>
      </div>
      <div className={styling("title")}>
        <h1>
          Give {user?.first_name} {user?.last_name} a <span>rating</span>
        </h1>
      </div>
      <div className={styling("rating-stars-container")}>
        <RatingSlider value={rating} setValue={setRating} />
      </div>
      <div className={styling("rating-text-review")}>
        <TextArea
          value={comment}
          setValue={setComment}
          label={"Leave a review"}
          placeholder={`Write something nice about ${user?.first_name}...`}
        />
      </div>
    </div>
  );
};

export default DesktopRating;
