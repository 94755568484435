import { useStyling } from "shiftly-ui";
import styles from "./SchedulerContextMenu.module.css";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommand } from "@fortawesome/pro-regular-svg-icons";
import { useScheduler } from "src/contexts/Scheduler";
import { useState } from "react";
import useIsContextActionAllowed from "./hooks/useIsContectActionAllowed";
import useContextActions from "./hooks/useContextActions";
import CancelShift from "../components/CancelShift";

function getOS() {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("Win") !== -1) return "Ctrl + ";
  return <FontAwesomeIcon icon={faCommand} />;
}
const SchedulerContextMenu = ({ dropdownVisible, position }) => {
  const styling = useStyling(styles);
  const [clipBoard, setClipBoard] = useState([]);
  const [cancelShift, setCancelShift] = useState(false);
  const { referencingShift, shiftTray, multiSelect } = useScheduler();

  const { isCopyAllowed, isPasteAllowed, isRepeatAllowed, doesShiftHaveStatus, isShiftOfType } =
    useIsContextActionAllowed({
      referencingShift,
      shiftTray,
      clipBoard,
      multiSelect,
    });

  const {
    copyAction,
    pasteAction,
    repeatAction,
    publishAction,
    unpublishAction,
    editAction,
    deleteAction,
    changeToPublicAction,
    changeToInternalAction,
  } = useContextActions({
    referencingShift,
    shiftTray,
    clipBoard,
    setClipBoard,
    multiSelect,
    setCancelShift,
  });

  const addS = (str) => (shiftTray.length > 1 ? str + "s" : str);

  return (
    <motion.div
      className={styling("container")}
      style={{
        top: position.y + "px",
        left: position.x + "px",
        overflow: "hidden",
      }}
      initial={{ height: 0, opacity: 0 }}
      animate={{
        height: dropdownVisible ? "auto" : 0,
        opacity: dropdownVisible ? 1 : 0,
      }}
      transition={{ duration: 0.3 }}
    >
      <CancelShift showModal={cancelShift} setShowModal={setCancelShift} shift={referencingShift} />
      {dropdownVisible && (
        <div className={styling("content")}>
          <div className={styling("row-section")}>
            <Row text={addS("Copy shift")} shortcut={"C"} disabled={!isCopyAllowed()} callback={copyAction} />
            <Row text={addS("Paste shift")} shortcut={"V"} disabled={!isPasteAllowed()} callback={pasteAction} />
          </div>
          <div className={styling("row-section")}>
            <Row
              text={`Repeat ${addS("shift")} on the next day`}
              disabled={!isRepeatAllowed()}
              callback={() => repeatAction("tomorrow")}
            />
            <Row
              text={`Repeat ${addS("shift")} for rest of the week`}
              disabled={!isRepeatAllowed()}
              callback={() => repeatAction("week")}
            />
          </div>
          <div className={styling("row-section")}>
            <Row text={addS("Publish shift")} disabled={doesShiftHaveStatus(["published"])} callback={publishAction} />
            <Row
              text={addS("Unpublish shift")}
              disabled={doesShiftHaveStatus(["unpublished"])}
              callback={unpublishAction}
            />
            <Row
              text={addS("Edit shift")}
              disabled={multiSelect || doesShiftHaveStatus(["confirmed", "expired", "completed"])}
              callback={editAction}
            />
            <Row
              text={addS("Delete shift")}
              disabled={doesShiftHaveStatus(["confirmed", "expired", "completed"])}
              callback={deleteAction}
            />
          </div>
          <div className={styling("row-section")}>
            <Row
              text={addS("Change to public shift")}
              disabled={!isShiftOfType("internal") || multiSelect}
              callback={changeToPublicAction}
            />
            <Row
              text={addS("Change to internal shift")}
              disabled={!isShiftOfType("shiftly") || doesShiftHaveStatus(["completed", "expired", "confirmed"])}
              callback={changeToInternalAction}
            />
          </div>
        </div>
      )}
    </motion.div>
  );
};
const Row = ({ text, shortcut, disabled = true, callback }) => {
  const styling = useStyling(styles);
  return (
    <div
      className={styling("row", disabled && "disabled")}
      onClick={() => {
        if (disabled) return;
        callback();
      }}
    >
      <p>{text}</p>
      {shortcut && (
        <div className={styling("shortcut")}>
          {getOS()}
          <p>{shortcut}</p>
        </div>
      )}
    </div>
  );
};
export default SchedulerContextMenu;
