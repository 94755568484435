import { usePushNotifications, Notifications } from "shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./BusinessNotificationCentre.module.css";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const BusinessNotificationCentre = () => {
  const [open, setOpen] = useState(false);
  const { activeLocation } = useShiftlyLocation();
  const { unreadNotifications, readNotifications, markAsRead } = usePushNotifications(activeLocation?._id);

  useEffect(() => {
    if (!open) {
      markAsRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return (
    <div className={styles["container"]}>
      <div className={styles["icon"]} onClick={() => setOpen((prev) => !prev)}>
        <FontAwesomeIcon icon={faBell} />
        {unreadNotifications.length > 0 && <div className={styles["badge"]}>{unreadNotifications.length}</div>}
      </div>
      <div className={clsx(styles["overlay"], open && styles["visible"])} onClick={() => setOpen(false)}></div>
      <motion.div
        initial={{ height: 0 }}
        animate={{
          height: open ? "auto" : 0,
          transition: {
            duration: 0.2,
          },
        }}
        className={styles["notifications-container"]}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Notifications unreadNotifications={unreadNotifications} readNotifications={readNotifications} />
      </motion.div>
    </div>
  );
};
export default BusinessNotificationCentre;