import {
  useUI,
  Modal,
  Calendar,
  Button,
  TimeSelector,
  Input,
  useToast,
  useFetch,
  roundNumber,
  convertStringTimeToDate,
  useAlerts,
  Dropdown,
  useStyling,
  TextArea,
  Tooltip,
  useAuth,
  withDisplay,
} from "shiftly-ui";
import styles from "./NewShiftModal.module.css";
import { useCallback, useEffect, useReducer, useRef } from "react";
import useBusiness from "src/hooks/useBusiness";
import { faCircleDollar, faQuestionCircle } from "@fortawesome/pro-regular-svg-icons";
import useShiftTotals from "src/hooks/useShiftTotals";
import moment from "moment-timezone";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import usePositions from "src/hooks/usePositions";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useShiftManager from "src/hooks/useShiftManager";

const initialState = {
  howMany: 1,
  mode: "Create",
  originalShift: {},
  selectedGroup: "",
  selectedLevel: "",
  selectedStaffMember: "",
  notes: "",
  tab: 0,
};

function shiftReducer(state, action) {
  switch (action.type) {
    case "SET_FIELD":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
}

const NewShiftModal = ({ isTablet }) => {
  const { user } = useAuth();
  const { newShiftModal, hideNewShiftModal, newShiftModalDefaults } = useUI();
  const [{ mode, originalShift, selectedGroup, selectedLevel, selectedStaffMember, notes, tab }, dispatch] = useReducer(
    shiftReducer,
    initialState
  );
  const toast = useToast();
  const calendarRef = useRef();
  const navigate = useNavigate();
  const { activeBusiness } = useBusiness();
  const { activeLocation } = useShiftlyLocation();
  const { dropdownPositions, getAvailableLevels, getPositionFromLevelAndGroup } = usePositions();
  const { deleteShifts } = useShiftManager();
  const {
    shiftDispatch,
    actionTypes,
    position,
    shiftDate,
    startTime,
    endTime,
    duration,
    baseWageFromAward,
    superContribution,
    totalEmployerContribution,
    lateNightPenalty,
    totalRange,
    shiftlyFees,
    increasedHourlyRateModifier,
  } = useShiftTotals();

  const { data: internalStaff, refresh } = useFetch({
    request: {
      entity: "InternalStaff",
      method: "get",
      id: "User.GetInternalStaff",
    },
    options: {
      enabled: !!user,
    },
  });

  useEffect(() => {
    dispatch({ type: "SET_FIELD", field: "tab", value: 0 });
  }, [newShiftModal]);

  const { confirm } = useAlerts();
  const styling = useStyling(styles);

  const postShiftAction = useCallback(() => {
    hideNewShiftModal();
    setTimeout(() => {
      shiftDispatch({ type: "RESET" });
      dispatch({ type: "RESET" });
      refresh("Shift.ShiftsBetweenDates");
      refresh("Shift.GetShiftsByLocation");
      refresh("InternalShift.ShiftsBetweenDates");
      calendarRef.current.refresh();
    }, 500);
  }, [hideNewShiftModal, shiftDispatch, dispatch, refresh, calendarRef]);

  const { post: postShift, isLoading } = useFetch({
    options: {
      onSuccess: () => {
        postShiftAction();
      },
    },
  });

  const setShiftFields = useCallback(
    (fields, dispatchFun) => {
      Object.entries(fields).forEach(([field, value]) => {
        dispatchFun({ type: actionTypes.SET_FIELD, field, value });
      });
    },
    [actionTypes]
  );

  const initializeShiftModal = useCallback(() => {
    if (!newShiftModalDefaults) return;
    const shift = newShiftModalDefaults.shift ? JSON.parse(newShiftModalDefaults.shift || "{}") : {};
    const type = newShiftModalDefaults.type;
    type === "internal" && dispatch({ type: "SET_FIELD", field: "tab", value: 1 });
    if (shift) {
      const startTime = shift.start_time ? moment.tz(shift.start_time, shift.timezone).format("HH:mm") : "09:00";
      const endTime = shift.end_time ? moment.tz(shift.end_time, shift.timezone).format("HH:mm") : "17:00";
      setShiftFields(
        {
          shiftDate: new Date(shift.date),
          position: shift.position,
          startTime,
          endTime,
          increasedHourlyRateModifier: shift.shift_rate_modifier || 0,
        },
        shiftDispatch
      );
      setShiftFields(
        {
          selectedLevel: shift.position?.classification_level,
          selectedGroup: shift.position?.group || shift.position_group,
          selectedStaffMember: type === "internal" ? shift.user?._id || shift?.user : "",
          mode: newShiftModalDefaults.mode === "edit" ? "Edit" : "Create",
          originalShift: shift,
          notes: shift.notes,
        },
        dispatch
      );
    } else {
      setShiftFields(
        {
          shiftDate: new Date(),
          startTime: "09:00",
          endTime: "17:00",
          increasedHourlyRateModifier: 0,
        },
        shiftDispatch
      );
      setShiftFields(
        {
          selectedLevel: "",
          selectedGroup: "",
          mode: "Create",
          notes: "",
        },
        dispatch
      );
    }
    if (newShiftModalDefaults.position) {
      const pos = JSON.parse(newShiftModalDefaults.position || "{}");
      setShiftFields(
        {
          selectedLevel: pos.classification_level,
          selectedGroup: pos.group?._id,
        },
        dispatch
      );
      setShiftFields(
        {
          position: pos,
          shiftDate: newShiftModalDefaults.shiftDate ? new Date(newShiftModalDefaults.shiftDate) : new Date(),
        },
        shiftDispatch
      );
    }
  }, [newShiftModalDefaults, setShiftFields, shiftDispatch, dispatch]);

  useEffect(() => {
    initializeShiftModal();
  }, [newShiftModalDefaults, initializeShiftModal]);

  useEffect(() => {
    if (!selectedGroup || !selectedLevel) return;
    const pos = getPositionFromLevelAndGroup(selectedGroup, selectedLevel);
    pos &&
      setShiftFields(
        {
          position: pos,
        },
        shiftDispatch
      );
  }, [selectedGroup, selectedLevel, shiftDispatch, setShiftFields, getPositionFromLevelAndGroup]);

  const handleSubmit = useCallback(
    async (status) => {
      try {
        if (!position && tab === 0) {
          throw new Error("Please select the position and level you would like the shift to be filled by");
        }
        if (!selectedStaffMember && tab === 1) {
          throw new Error("Please select the staff member you would like to schedule");
        }
        if (!shiftDate) {
          throw new Error("Please select a date for the shift");
        }
        if (!startTime) {
          throw new Error("Please select a start time for the shift");
        }
        if (!endTime) {
          throw new Error("Please select an end time for the shift");
        }
        const { startDate, endDate } = convertStringTimeToDate(shiftDate, startTime, endTime);
        if (startDate >= endDate) {
          throw new Error("Start time must be before end time");
        }
        const shift = {
          ...originalShift,
          award_code: activeBusiness?.industry?.award_code,
          industry: activeBusiness?.industry?._id,
          business: activeBusiness._id,
          location: activeLocation._id,
          timezone: activeLocation.timezone,
          position: position?._id,
          position_group: selectedGroup,
          start_time: new Date(startDate),
          end_time: new Date(endDate),
          shift_rate_modifier: increasedHourlyRateModifier,
          status,
          responsibilities: position?.responsibilities,
          user: tab === 1 ? selectedStaffMember : originalShift?.user,
          notes,
        };
        if (originalShift?.applicants?.length > 0) {
          hideNewShiftModal();
          if (
            !(await confirm({
              label: "Are you sure you want to edit this shift?",
              text: (
                <p>Updating shift details will remove all your current applicants, they will need to apply again.</p>
              ),
              inverse: true,
              confirmText: "Confirm",
            }))
          )
            return;
        }
        postShift({
          entity: tab === 0 ? "Shift" : "InternalShift",
          method: mode === "Create" ? "create" : "update",
          data: shift,
          criteria: {
            _id: shift._id,
          },
        });
      } catch (error) {
        toast.error(error.message, "Missing Fields");
      }
    },
    [
      activeBusiness,
      activeLocation,
      position,
      shiftDate,
      startTime,
      endTime,
      increasedHourlyRateModifier,
      postShift,
      toast,
      mode,
      originalShift,
      confirm,
      hideNewShiftModal,
      selectedGroup,
      selectedStaffMember,
      tab,
      notes,
    ]
  );

  const handleDeleteShift = useCallback(async () => {
    postShiftAction();
    deleteShifts([originalShift]);
  }, [deleteShifts, originalShift, postShiftAction]);

  return (
    <Modal
      label={<ModalLabel tab={tab} setTab={(v) => dispatch({ type: "SET_FIELD", field: "tab", value: v })} />}
      showModal={!!newShiftModal}
      setShowModal={hideNewShiftModal}
      className={styling("new-shift-modal")}
    >
      <div className={styling("modal-content")}>
        <div className={styling("position-container")}>
          <Dropdown
            label={"What position are you filling?"}
            options={dropdownPositions}
            placeholder={"Select a position"}
            className={styling("select-position")}
            value={selectedGroup}
            setValue={(v) => dispatch({ type: "SET_FIELD", field: "selectedGroup", value: v })}
            onChange={(e) => dispatch({ type: "SET_FIELD", field: "selectedGroup", value: "" })}
          />
          {tab === 0 ? (
            <Dropdown
              label={"At which level?"}
              options={getAvailableLevels(selectedGroup)}
              placeholder={"Select a level"}
              className={styling("select-level")}
              value={selectedLevel}
              setValue={(v) => dispatch({ type: "SET_FIELD", field: "selectedLevel", value: v })}
              ml={"20px"}
              disabled={!selectedGroup}
              link={
                selectedGroup
                  ? {
                      label: "What's this?",
                      onClick: () => {
                        hideNewShiftModal();
                        navigate("/positions/position-levels/" + selectedGroup);
                      },
                    }
                  : {}
              }
            />
          ) : (
            <Dropdown
              label={"For which staff member?"}
              options={internalStaff.map((staff) => ({
                label: `${staff.first_name} ${staff.last_name}`,
                value: staff._id,
              }))}
              disabled={!selectedGroup}
              pl={tab === 0 ? "10px" : "0"}
              placeholder={"Select a staff member"}
              className={styling("select-position")}
              value={selectedStaffMember}
              setValue={(v) => dispatch({ type: "SET_FIELD", field: "selectedStaffMember", value: v })}
              ml={"20px"}
              link={{
                label: "Manage Internal Staff",
                onClick: () => {
                  navigate("/people?tab=internal");
                  hideNewShiftModal();
                },
              }}
            />
          )}
        </div>
        <div className={styling("dates-container")}>
          <div className={styling("time-container")}>
            <div className={styling("time-selectors")}>
              <div className={styling("time-select")}>
                <div
                  className={styling("selector")}
                  style={{
                    width: tab === 0 ? "100%" : "auto",
                  }}
                >
                  <TimeSelector
                    label={"Start Time"}
                    value={startTime}
                    setValue={(v) => setShiftFields({ startTime: v }, shiftDispatch)}
                  />
                </div>
                <div
                  className={styling("selector")}
                  style={{
                    width: tab === 0 ? "100%" : "auto",
                  }}
                >
                  <TimeSelector
                    label={"End Time"}
                    value={endTime}
                    setValue={(v) => setShiftFields({ endTime: v }, shiftDispatch)}
                  />
                </div>
              </div>
              {tab === 0 && (
                <div className={styling("wage-selector")}>
                  <Input
                    value={increasedHourlyRateModifier}
                    name={"hourly_rate_modifier"}
                    setValue={(v) => setShiftFields({ increasedHourlyRateModifier: v }, shiftDispatch)}
                    icon={{ side: "right", icon: faCircleDollar }}
                  />
                </div>
              )}
            </div>
            <div className={styling("total-notes")}>
              {tab === 0 && (
                <div className={styling("total-container")}>
                  <div className={styling("total-info")}>
                    <div className={styling("total-line")}>
                      <p>Award Rate ({duration} hours)</p>
                      <div></div>
                      <p>
                        ${roundNumber(baseWageFromAward?.min * duration, 2)} - $
                        {roundNumber(baseWageFromAward?.max * duration, 2)}
                      </p>
                    </div>
                    <div className={styling("total-line")}>
                      <p>Employer Contribution</p>
                      <div></div>
                      <p>${totalEmployerContribution}</p>
                    </div>
                    <div className={styling("total-line")}>
                      <p>Late night penalty</p>
                      <div></div>
                      <p>${lateNightPenalty}</p>
                    </div>
                    <div className={styling("total-line")}>
                      <p>Super Contribution</p>
                      <div></div>
                      <p>
                        ${superContribution?.min} - ${superContribution?.max}
                      </p>
                    </div>
                    <div className={styling("total-line")}>
                      <p>Shiftly Fee</p>
                      <div></div>
                      <p>
                        ${shiftlyFees?.min} - ${shiftlyFees?.max}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {!isTablet && (
                <div
                  className={styling("notes")}
                  style={{
                    paddingLeft: tab === 0 ? "10px" : "0px",
                  }}
                >
                  <TextArea
                    placeholder={"Add any notes for the shift"}
                    rows={5}
                    value={notes}
                    setValue={(v) => dispatch({ type: "SET_FIELD", field: "notes", value: v })}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styling("calendar-container")}>
            <Calendar
              minDate={new Date()}
              range={false}
              label={"Select shift date"}
              value={shiftDate}
              setValue={(v) => setShiftFields({ shiftDate: v }, shiftDispatch)}
              ref={calendarRef}
            />
          </div>
        </div>
        <div className={styling("modal-footer")}>
          {mode === "Edit" && (
            <div className={styling("delete-shift")}>
              <Button theme={"secondary-outline"} onClick={handleDeleteShift}>
                Delete Shift
              </Button>
            </div>
          )}
          {tab === 0 && (
            <div className={styling("calc-container")}>
              <div className={styling("calc-total")}>
                <Tooltip
                  position={"top"}
                  title={"Why is this a range?"}
                  text={
                    "The exact amount depends on the age of the shifter who fills the shift. According to the relevant award, adult workers are entitled to higher pay rates."
                  }
                >
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </Tooltip>
                <h6>
                  Total Charge:
                  <span>
                    ${totalRange.min} - ${totalRange.max}
                  </span>
                </h6>
              </div>
            </div>
          )}
          <div className={styling("footer-buttons")}>
            <Button theme={"secondary"} onClick={() => handleSubmit("unpublished")} disabled={isLoading}>
              Save as draft
            </Button>
            <Button theme={"primary"} onClick={() => handleSubmit("published")} disabled={isLoading}>
              {isLoading ? "Saving Shift..." : "Publish Shift"}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
const ModalLabel = ({ tab, setTab }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("ml-container")}>
      <div className={styling("ml-tab", tab === 0 && "mlt-active")} onClick={() => setTab(0)}>
        <h1>Create a shift</h1>
      </div>
      <div className={styling("ml-tab", tab === 1 && "mlt-active")} onClick={() => setTab(1)}>
        <h1>Internal scheduling</h1>
      </div>
      <div className={styling("ml-slider-container")}>
        <div className={styling("ml-slider-wrapper")}>
          <div className={styling("ml-slider")} style={{ left: `${tab * 50}%` }}></div>
        </div>
      </div>
    </div>
  );
};
export default withDisplay(NewShiftModal);
