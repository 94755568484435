import { useAuth, useUI } from "shiftly-ui";
import { useEffect } from "react";
import styles from "./Main.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import NewShiftModal from "src/pages/app/shifts/components/NewShiftModal";
import BusinessNavigation from "./business_navigation/BusinessNavigation";

const Main = ({ children }) => {
  const {
    auth: { isAuthenticated },
  } = useAuth();
  const { loading } = useUI();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (loading || pathname === "/portal") return;
    if (!isAuthenticated) {
      navigate("/portal?page=signup");
    }
  }, [isAuthenticated, navigate, loading, pathname]);
  return (
    <div className={styles["container"]}>
      <BusinessNavigation />
      <NewShiftModal />
      <div className={styles["content-wrapper"]}>{children}</div>
    </div>
  );
};
export default Main;
