import { useStyling } from "shiftly-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Header.module.css";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useChatContext } from "@copilotkit/react-ui";
const Header = () => {
  const styling = useStyling(styles);
  const { setOpen } = useChatContext();
  return (
    <div className={styling("container")} onClick={() => setOpen(false)}>
      <div className={styling("title")}>
        <p>Get help with AI</p>
        <h2>Ask Shiftly</h2>
      </div>
      <FontAwesomeIcon icon={faTimes} className={styling("chevron")} />
    </div>
  );
};
export default Header;