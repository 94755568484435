import { useStyling, useFetch, responsibilities, useDelayUnmount, LoadingSpinner, Link } from "shiftly-ui";
import { useParams } from "react-router-dom";
import styles from "./PositionLevels.module.css";
import StandardLayout from "src/pages/settings/components/StandardLayout";
import useBusiness from "src/hooks/useBusiness";
const PositionLevels = () => {
  const styling = useStyling(styles);
  const { group } = useParams();
  const { activeBusiness } = useBusiness();
  const award_code = activeBusiness?.industry?.award_code;
  const {
    data: [activeGroup],
    isLoading,
  } = useFetch({
    request: {
      entity: "PositionGroup",
      criteria: {
        _id: group,
      },
    },
    dependency: group,
  });
  const positionResponsibilities = responsibilities[award_code]?.[activeGroup?.stream];
  const loading = useDelayUnmount(isLoading);
  return loading ? (
    <div className={styling("loading")}>
      <LoadingSpinner />
    </div>
  ) : (
    <StandardLayout
      heading={
        <h1>
          {activeGroup?.name} <span>Levels</span>
        </h1>
      }
      description={
        <p>
          Review the responisbilities of each level. <br />
          <strong>{activeGroup?.name}</strong>: Classified under the
          <Link className={styling("stream")} to={`https://library.fairwork.gov.au/award/?krn=${award_code}`}>
            {activeGroup?.stream}
          </Link>
          stream
        </p>
      }
      size="medium"
    >
      <div className={styling("container")}>
        <div className={styling("award")}>
          <Link to={`https://library.fairwork.gov.au/award/?krn=${award_code}`}>View Full Award</Link>
        </div>
        <table className={styling("table")}>
          <thead>
            <tr>
              <th>{activeGroup?.name} Levels</th>
              <th>Award Definition of Level</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(positionResponsibilities || {}).map(([level, responsibilities]) => {
              return (
                <tr key={level}>
                  <td>Level {level}</td>
                  <td>
                    <ul>
                      {responsibilities.map((responsibility, index) => (
                        <li key={index}>{responsibility}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </StandardLayout>
  );
};
export default PositionLevels;