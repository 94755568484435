import { Modal, Toggle, Checkbox, Button, useFetch, useAuth, FormV2, ModalLabel } from "shiftly-ui";
import styles from "./BusinessAccountAddNewPaymentMethod.module.css";
import { useCallback, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstitution, faMoneyBill } from "@fortawesome/pro-regular-svg-icons";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BusinessAccountAddNewPaymentMethodForm from "./components/BusinessAccountAddNewPaymentMethodForm";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const BusinessAccountAddNewPaymentMethod = ({ setShowModal, showModal, tab, setTab }) => {
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const [data, setData] = useState({});
  const stripeRef = useRef();
  const { data: clientSecret, refresh } = useFetch({
    request: {
      entity: "PaymentMethod",
      method: "createSetupIntent",
      id: "PaymentMethod.CreateSetupIntent",
    },
    options: {
      onSuccess: () => {
        refresh("PaymentMethod.GetDefaultPaymentMethod");
      },
      select: (setup_intent) => {
        return setup_intent.client_secret;
      },
    },
  });
  const handleSubmit = useCallback(async (data) => {
    return await stripeRef.current.handleSubmit(data);
  }, []);
  return (
    <Modal
      className={styles["modal"]}
      label={<ModalLabel text="Payment Method" />}
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <FormV2
        onSubmit={handleSubmit}
        resetOnSubmit
        data={data}
        setData={setData}
        initial={{
          first_name: user.first_name,
          last_name: user.last_name,
          account_name: user?.first_name + " " + user?.last_name,
        }}
      >
        <div className={styles["content"]}>
          <div className={styles["details"]}>
            <div className={styles["tab-container"]}>
              <Toggle
                tab={tab}
                setTab={setTab}
                tabs={[
                  {
                    text: "Account Details",
                    icon: <FontAwesomeIcon icon={faInstitution} />,
                  },
                  {
                    text: "Card Details",
                    icon: <FontAwesomeIcon icon={faMoneyBill} />,
                  },
                ]}
              />
            </div>
            {typeof clientSecret === "string" && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <BusinessAccountAddNewPaymentMethodForm
                  tab={tab}
                  setShowModal={setShowModal}
                  ref={stripeRef}
                  clientSecret={clientSecret}
                  setLoading={setLoading}
                />
              </Elements>
            )}
          </div>
        </div>
        <div className={styles["footer"]}>
          <Checkbox label={"Set as default payment method"} name={"is_default"} />
          <Button loading={loading} type="submit" className={styles["submit-btn"]}>
            Add Payment Method
          </Button>
        </div>
      </FormV2>
    </Modal>
  );
};
export default BusinessAccountAddNewPaymentMethod;