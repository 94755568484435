import { useFetch, useAlerts } from "shiftly-ui";
import { useCallback, useMemo } from "react";
import { useDrag, useDrop } from "react-dnd";
import moment from "moment-timezone";
import useShiftManager from "src/hooks/useShiftManager";

const useShiftDrag = ({ shift, position, index, periods, status }) => {
  const { confirm } = useAlerts();
  const { handleTimeUpdate, normaliseShift } = useShiftManager();

  const {
    post: updateShift,
    refresh,
    updateCache,
  } = useFetch({
    options: {
      onMutate: ({ data: shift, payload, method }) => {
        if (method === "delete") return;
        updateShiftCache(payload.type, shift, {
          position: payload.position,
          location: payload.location,
          business: payload.business,
          position_group: payload.position_group?._id,
          user: payload.user || undefined,
        });
      },
      onError: () => {
        refresh("Shift.ShiftsBetweenDates");
        refresh("InternalShift.ShiftsBetweenDates");
      },
      onSuccess: () => {
        refresh("Shift.ShiftsBetweenDates");
        refresh("InternalShift.ShiftsBetweenDates");
      },
    },
  });
  // Utility to update the cache based on shift type
  const updateShiftCache = useCallback(
    (type, shift, payload) => {
      const cacheKey = type === "shiftly" ? "Shift.ShiftsBetweenDates" : "InternalShift.ShiftsBetweenDates";
      updateCache(cacheKey, (oldData = []) => {
        const findIndex = oldData.findIndex((oldShift) => oldShift._id === shift._id);
        const newShift = { ...shift, ...payload };
        if (findIndex === -1) return [...oldData, newShift];
        const newData = [...oldData]; // Shallow clone
        newData[findIndex] = newShift;
        return newData;
      });
    },
    [updateCache]
  );

  const handleDropShift = useCallback(
    async (item) => {
      const { shift: draggedShift, position: draggedPosition } = item;

      //Exit anything external or invalid
      if (draggedPosition.type === "external" || position?.type === "external" || !draggedShift._id) return;

      const slotDay = moment.tz(periods.current[index], draggedShift?.timezone);
      const { newStartTime, newEndTime } = handleTimeUpdate(draggedShift, slotDay);

      const newShift = {
        ...draggedShift,
        ...normaliseShift(draggedShift),
        start_time: newStartTime,
        end_time: newEndTime,
      };

      let payload = {
        location: draggedShift?.location,
        position,
        position_group: position?.group,
        business: draggedShift?.business,
        user: draggedShift?.user,
      };

      if (position.type === "internal" && draggedPosition?.type === "internal") {
        //Dropping internal shift on internal shift
        await updateShift({
          entity: "InternalShift",
          method: "update",
          criteria: { _id: draggedShift._id },
          data: newShift,
          payload: { ...payload, type: "internal" },
        });
      } else if (!position.type && draggedPosition?.type === "internal") {
        //Dropping internal shift on external slot
        if (
          !(await confirm({
            label: "Publish Shift",
            text: "Are you sure you'd like to publish this shift? You can always edit it later.",
            confirmText: "Publish",
            cancelText: "Cancel",
          }))
        )
          return;
        delete newShift._id;
        await updateShift({
          entity: "Shift",
          method: "create",
          data: { ...newShift, user: null, status: "published" },
          payload: { ...payload, type: "shiftly" },
        });
        await updateShift({
          entity: "InternalShift",
          method: "delete",
          criteria: { _id: draggedShift._id },
          payload: { type: "internal" },
        });
        return;
      } else if (!draggedPosition?.type && position?.type === "internal") {
        //Dropping external shift on internal slot
        return;
      } else {
        //Dropping external shift on external slot
        await updateShift({
          entity: "Shift",
          method: "update",
          data: newShift,
          criteria: { _id: draggedShift._id },
          payload: { ...payload, type: "shiftly" },
        });
      }
    },
    [updateShift, periods, index, position, handleTimeUpdate, confirm, normaliseShift]
  );
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "Shift",
      item: { shift, index, position },
      collect: (monitor) => ({ isDragging: !!monitor.isDragging() }),
    }),
    [shift, index, position]
  );
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "Shift",
      drop: (item) => handleDropShift(item),
      collect: (monitor) => ({ isOver: !!monitor.isOver() }),
    }),
    [handleDropShift]
  );
  const dragRef = useMemo(() => {
    const undraggable = ["confirmed", "expired", "external"];
    if (status === "add" || undraggable.includes(shift.status)) return null;
    return drag;
  }, [drag, status, shift.status]);
  return useMemo(
    () => ({
      dragRef,
      dropRef: drop,
      isDragging,
      isOver,
      updateShift,
    }),
    [dragRef, drop, isDragging, isOver, updateShift]
  );
};
export default useShiftDrag;
