import { Link, useFetch, Each } from "shiftly-ui";
import styles from "./MessagesUpcomingShifters.module.css";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const MessagesUpcomingShifters = ({ setActiveConversation = () => {} }) => {
  const { activeLocation } = useShiftlyLocation();
  const [parent] = useAutoAnimate();
  const { data: upcomingShifters } = useFetch({
    request: {
      entity: "ShiftApplication",
      method: "get",
      criteria: {
        status: { $in: ["accepted", "pending"] },
        location: activeLocation?._id,
      },
      populate: ["user", "shift"],
    },
    dependecy: activeLocation?._id,
    options: {
      select: (data) => {
        return data.map((application) => ({ user: application?.user, shift: application?.shift }));
      },
    },
  });
  const { data: existingConversations } = useFetch({
    request: {
      entity: "Conversation",
      method: "getExistingConversations",
      criteria: {
        location: activeLocation?._id,
      },
      id: "Conversation.GetExistingConversations",
    },
    dependency: activeLocation?._id,
  });
  const { data: shifterProfiles } = useFetch({
    request: {
      entity: "Profile",
      method: "get",
      criteria: {
        user: { $in: upcomingShifters?.map((shifter) => shifter?.user?._id) },
      },
    },
    dependency: upcomingShifters.length,
    options: {
      select: (data) => {
        return data.map((p) => {
          const shifter = upcomingShifters.find((shifter) => shifter?.user?._id === p?.user) || {};
          return {
            ...p,
            ...(shifter.user || {}),
            shift: shifter.shift || {},
          };
        });
      },
    },
  });
  const {
    post: createNewConversation,
    refresh,
    isLoading,
  } = useFetch({
    options: {
      onSuccess: (data = []) => {
        refresh("Conversation.GetExistingConversations");
        const user = shifterProfiles.find((shifter) => shifter._id === data[0]?.user);
        data?.[0] && setActiveConversation({ ...data[0], user });
      },
    },
  });
  const handleCreateNewChat = (shifter) => {
    const conversation = existingConversations.find((conversation) => conversation?.user?._id === shifter._id);
    if (conversation) {
      setActiveConversation(conversation);
    } else {
      !isLoading &&
        createNewConversation({
          entity: "Conversation",
          method: "create",
          data: {
            user: shifter._id,
            location: activeLocation?._id,
            start_date: new Date(),
            last_sent: new Date(),
          },
        });
    }
  };
  return (
    <div className={styles["container"]}>
      <div className={styles["title"]}>
        <h4>Upcoming Shifters</h4>
        <Link to={"/people?tab=confirmed"}>View All</Link>
      </div>
      <div className={styles["profiles"]} ref={parent}>
        {shifterProfiles.length > 0 ? (
          <Each
            of={shifterProfiles}
            render={(profile = {}, index) => (
              <div className={styles["profile-container"]} key={index} onClick={() => handleCreateNewChat(profile)}>
                <div className={styles["profile-image"]}>
                  {profile.profile_picture ? (
                    <img src={profile.profile_picture} alt="profile" />
                  ) : (
                    <div className={styles["initials-icon"]}>
                      <p>{profile.first_name?.charAt(0)}</p>
                    </div>
                  )}
                </div>
                <div className={styles["profile-info"]}>
                  <h4>
                    {profile.first_name} {profile.last_name}
                  </h4>
                  <p>{profile.shift.prettyDate}</p>
                </div>
              </div>
            )}
          />
        ) : (
          <p className={styles["no-upcoming"]}>
            No upcoming shifters.
            <br />
            Approve a shift to view them here!
          </p>
        )}
      </div>
    </div>
  );
};
export default MessagesUpcomingShifters;
