import { useEffect, useMemo, useState } from "react";

import { withDisplay, useFetch } from "shiftly-ui";
import MobileRating from "./MobileRating";
import DesktopRating from "./DesktopRating";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";

const RateShifter = ({ isMobile }) => {
  const [open, setOpen] = useState(false);
  const { activeLocation } = useShiftlyLocation();

  const { data: shifts } = useFetch({
    request: {
      entity: "Shift",
      method: "get",
      criteria: {
        status: "completed",
        rated: false,
        location: activeLocation?._id,
      },
      populate: ["user"],
      id: "Shift.GetUnratedShifts",
    },
    dependency: activeLocation?._id,
  });

  const props = useMemo(
    () => ({
      shifts,
      open,
      setOpen,
    }),
    [shifts, open, setOpen]
  );

  useEffect(() => {
    if (shifts?.length) {
      setTimeout(() => {
        setOpen(true);
      }, 3000);
    }
  }, [shifts]);

  return isMobile ? <MobileRating {...props} /> : <DesktopRating {...props} />;
};

export default withDisplay(RateShifter);
