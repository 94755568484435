import { RadioButtons, useFetch } from "shiftly-ui";
import styles from "./HQNewBusinessSetup.module.css";
import NewBusinessRadioButtonCard from "./HQNewBusinessRadioButtonCard";
import { faBurger, faForkKnife, faHotel, faMartiniGlass } from "@fortawesome/pro-regular-svg-icons";
const iconMap = {
  "Restaurant or Cafe": faForkKnife,
  "Fast Food": faBurger,
  "Bar or Nightclub": faMartiniGlass,
  "Other Hospitality": faHotel,
};
const NewBusinessIndustry = ({ data, setActiveStep }) => {
  const { data: industries } = useFetch({ request: { entity: "Industry" } });
  return (
    <div className={styles["container"]}>
      <div className={styles["heading"]}>
        <h1>
          Which of these <span>best describes</span> your business?
        </h1>
      </div>
      <div className={styles["large-content"]}>
        <RadioButtons
          required
          align="center"
          options={industries.map((industry) => ({
            label: industry?.name,
            value: industry?._id,
            content: (
              <NewBusinessRadioButtonCard
                label={industry?.name}
                award={industry?.award_code}
                icon={iconMap[industry?.name]}
                active={data?.category === industry?._id}
                onClick={() => {
                  setTimeout(() => {
                    setActiveStep(2);
                  }, 300);
                }}
              />
            ),
          }))}
          type="cards"
          name="industry"
        />
      </div>
    </div>
  );
};
export default NewBusinessIndustry;