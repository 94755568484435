import { Overlay, Each, Button, useUI, usePushNotification, useStyling, Notifications } from "shiftly-ui";
import styles from "./BusinessNavTabletMode.module.css";
import { useEffect, useState } from "react";
import logo from "shiftly-ui/assets/logos/shiftly logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faSignalStream } from "@fortawesome/pro-regular-svg-icons";
import { motion } from "framer-motion";
import BusinessLocationSelector from "./BusinessLocationSelector";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import usePositions from "src/hooks/usePositions";
import BusinessNavDropDown from "./BusinessNavDropDown";
const BusinessNavTabletMode = (props) => {
  const [showNav, setShowNav] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const { activeLocation } = useShiftlyLocation();
  const pushNotifications = usePushNotification(activeLocation?._id);
  const { showNewShiftModal, setNewShiftModalDefaults } = useUI();
  const { pathname } = window.location;
  const navigate = useNavigate();
  const { availablePositions } = usePositions();
  const styling = useStyling(styles);
  useEffect(() => {
    if (!showNav) setShowNotifications(false);
  }, [showNav]);
  return (
    <>
      <motion.div
        initial={{ x: -450 }}
        animate={{ x: showNav ? (showNotifications ? 300 : -150) : -450 }}
        transition={{ duration: 0.3 }}
        className={styling("notification-centre")}
        onClick={() => setShowNotifications((prev) => !prev)}
      >
        <p className={styling("notification-heading")}>Notifications</p>
        <Notifications {...pushNotifications} />
      </motion.div>
      <Overlay setOpen={setShowNav} open={showNav} />
      <motion.div className={styling("account-dropdown")}>
        <BusinessNavDropDown open={showNav} setOpen={setShowNav} />
      </motion.div>
      <motion.div
        initial={{ x: -300 }}
        animate={{ x: showNav ? 0 : -300 }}
        transition={{ duration: 0.3 }}
        className={styling("navbar-container", showNotifications && "no-radius")}
      >
        {pathname !== "/" && (
          <motion.div
            animate={{ x: showNotifications ? 490 : 40 }}
            transition={{ duration: 0.3 }}
            className={styling("chevron")}
            onClick={() => setShowNav((prev) => !prev)}
          >
            <FontAwesomeIcon icon={showNav ? faChevronLeft : faChevronRight} />
          </motion.div>
        )}
        <div className={styling("content-container")}>
          <div className={styling("heading")}>
            <div
              className={styling("logo")}
              onClick={() => {
                navigate("/shifts");
                setShowNav(false);
              }}
            >
              <img src={logo} alt="Shiftly Logo" />
            </div>
            <div className={styling("notification")} onClick={() => setShowNotifications((prev) => !prev)}>
              <FontAwesomeIcon icon={faBell} />
            </div>
          </div>
          <div className={styling("location-selector")}>
            <BusinessLocationSelector {...props} setShowNav={setShowNav} />
          </div>
          <div className={styling("links")}>
            <Each
              of={props.links}
              render={({ key, ...link }) => <NavItem key={key} {...link} setShowNav={setShowNav} />}
            />
          </div>
          <div className={styling("footer")}>
            <Button
              className={styling("create-btn")}
              onClick={() => {
                showNewShiftModal(true);
                setShowNav(false);
                setNewShiftModalDefaults({
                  shiftDate: new Date(),
                });
              }}
              icon={faSignalStream}
              disabled={availablePositions.length === 0}
            >
              Create New Shift
            </Button>
          </div>
        </div>
      </motion.div>
    </>
  );
};
const NavItem = ({ label, icon, path, badge, setShowNav }) => {
  const windowPth = window.location.pathname;
  const styling = useStyling(styles);
  const navigate = useNavigate();
  return (
    <div
      className={styling("nav-item", windowPth === path && "active")}
      onClick={() => {
        navigate(path);
        setShowNav(false);
      }}
    >
      <div className={styling("icon")}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <p>{label}</p>
      {badge > 0 && <div className={styling("badge")}>{badge}</div>}
    </div>
  );
};
export default BusinessNavTabletMode;