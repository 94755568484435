import { useFetch } from "shiftly-ui";
import { useCallback } from "react";
import useMessagesCriteria from "./useMessagesCriteria";
const useReadMessages = (activeConversation) => {
  const criteria = useMessagesCriteria(activeConversation);
  const { post: markMessagesAsRead, updateCache } = useFetch({
    options: {
      onSuccess: () => {
        updateCache("Message.GetMessagesWithAccess", (oldData = []) => {
          return oldData.map((message) => {
            if (message.conversation === activeConversation._id) {
              return { ...message, unread: false };
            }
            return message;
          });
        });
      },
    },
  });
  const readMessage = useCallback(
    (id) => {
      markMessagesAsRead({
        entity: "Message",
        method: "markAllAsRead",
        data: {
          conversation_id: id,
          ...criteria,
        },
      });
    },
    [markMessagesAsRead, criteria]
  );
  return readMessage;
};
export default useReadMessages;