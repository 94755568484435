import { useStyling, withDisplay } from "shiftly-ui";
import React, { useMemo, useState, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SchedulerShiftSlot.module.css";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import SchedulerViewShift from "./SchedulerViewShift";
import ApplicantProfile from "../../../people/components/Applicants/ApplicantProfile";
import useSlotStatus from "./useSlotStatus";
import useShiftDrag from "../hooks/useShiftDrag";
import { useScheduler } from "src/contexts/Scheduler";
const now = new Date();
const yesterdayMidnight = new Date(now.getFullYear(), now.getMonth(), now.getDate());
yesterdayMidnight.setSeconds(-1);
const ShiftSlot = React.memo(({ index, position, shift = {}, status, isLaptop }) => {
  const [viewShift, setViewShift] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const { handleSlotClick, periods, setReferencingShift, shiftTraySlotClick, multiSelect, shiftTray } = useScheduler();
  const { dragRef, dropRef: drop, isDragging, isOver } = useShiftDrag({ shift, position, index, periods, status });
  const disabled = useMemo(() => {
    return periods.current[index] < yesterdayMidnight && !shift.status;
  }, [periods, index, shift]);
  const { borderColour, iconBorder, icon, text, backgroundColour, imageFit, profile } = useSlotStatus({ shift });
  const styling = useStyling(styles);
  const isShiftInTray = shiftTray.some((trayShift) => trayShift.shift?._id === shift._id);
  const handleClick = useCallback(() => {
    if (multiSelect) {
      shiftTraySlotClick(index, position, shift);
      return;
    }
    if (position.type === "external") return;
    if (!shift.status && !disabled) {
      handleSlotClick(index, position, shift);
    } else if (shift.status) {
      setViewShift(true);
    }
  }, [disabled, handleSlotClick, index, position, shift, shiftTraySlotClick, multiSelect]);
  const renderStatusContent = useCallback(() => {
    if (disabled || (position?.type === "external" && !shift?.id)) return "-";
    if (status === "free" || status === "add") {
      return <FontAwesomeIcon icon={faPlus} className={styling("plus-icon")} />;
    }
    const shiftTime = `${shift?.prettyStartTime} - ${shift?.prettyEndTime}`;
    return (
      <div className={styling("info-container")}>
        <p className={styling("shift-time")}>{shiftTime}</p>
        {isLaptop && (
          <div className={styling("shiftly-image")} style={{ border: `1px solid ${iconBorder}` }}>
            <img
              src={icon}
              alt="Shiftly Logo"
              style={{ objectFit: imageFit, padding: imageFit === "cover" ? "0" : "4px" }}
            />
          </div>
        )}
        {text}
      </div>
    );
  }, [disabled, status, shift, isLaptop, styling, iconBorder, icon, imageFit, text, position]);
  return (
    <div
      className={styling("slot", isOver && "dropping", isShiftInTray && "shift-selected")}
      ref={drop}
      onContextMenu={() => {
        setReferencingShift(shift);
      }}
      onClick={() => {
        setReferencingShift(shift);
      }}
    >
      <ApplicantProfile
        applicant={{ profile, user: shift?.user, _id: "", shift }}
        show={showProfile}
        setShow={setShowProfile}
        showActions={false}
      />
      <SchedulerViewShift
        view={viewShift}
        setView={setViewShift}
        shift={shift}
        status={status}
        profile={profile}
        setShowProfile={setShowProfile}
      />
      <div
        className={styling("shift-slot", shift.status ?? "free", disabled && "disabled", isDragging && "dragging")}
        onClick={handleClick}
        style={{ borderBottom: borderColour ? `3px solid ${borderColour}` : "none", backgroundColor: backgroundColour }}
        aria-disabled={disabled}
        ref={dragRef}
      >
        {renderStatusContent()}
      </div>
    </div>
  );
});
export default withDisplay(ShiftSlot);