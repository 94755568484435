import clsx from "clsx";
import styles from "./HQNewBusinessRadioButtonCard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const HQMultipleCard = ({ icon, text, subtext, active, onClick = () => {} }) => {
  return (
    <div className={clsx(styles["container"], active && styles["active"])} onClick={onClick}>
      <FontAwesomeIcon icon={icon} />
      <h6>{text}</h6>
      <p>{subtext}</p>
    </div>
  );
};
export default HQMultipleCard;