import { Modal, useStyling, ModalLabel, FormFlex, Input, Button, useFetch, Each, Checkbox, FormV2 } from "shiftly-ui";
import styles from "./InternalStaffModal.module.css";
import { useCallback, useEffect, useState } from "react";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const InternalStaffModal = ({ showModal, setShowModal, initial }) => {
  const [availableForLocations, setAvailableForLocations] = useState([]);
  const { allLocations, activeLocation } = useShiftlyLocation();
  const mode = initial?._id ? "Edit" : "Add";
  useEffect(() => {
    setAvailableForLocations(initial?.locations.length ? initial?.locations : [activeLocation?._id]);
  }, [activeLocation, initial]);
  const styling = useStyling(styles);
  const {
    post: saveUser,
    isLoading,
    refresh,
    isError,
  } = useFetch({
    options: {
      onSuccess: () => {
        refresh("User.GetInternalStaff");
        setShowModal(false);
      },
    },
  });
  const handleSubmit = useCallback(
    async (data) => {
      if (initial._id) {
        return await saveUser({
          entity: "InternalStaff",
          method: "update",
          criteria: { _id: initial._id },
          data: { ...data, locations: availableForLocations },
        });
      } else {
        return await saveUser({
          entity: "InternalStaff",
          method: "create",
          data: { ...data, locations: availableForLocations },
        });
      }
    },
    [saveUser, initial, availableForLocations]
  );
  return (
    <Modal
      onSubmit={handleSubmit}
      showModal={showModal}
      setShowModal={setShowModal}
      label={<ModalLabel text={`${mode} internal staff member`} />}
    >
      <div className={styling("container")}>
        <FormV2 initial={initial} onSubmit={handleSubmit}>
          <FormFlex>
            <Input name={"first_name"} />
            <Input name={"last_name"} />
          </FormFlex>
          <Input name={"email"} submitOnEnter />
          <p className={styling("avail-title")}>Available for locations:</p>
          <div className={styling("available-locations")}>
            <Each
              of={allLocations}
              render={({ key, ...location }) => {
                return (
                  <div className={styling("location")} key={key}>
                    <Checkbox
                      label={location?.name}
                      disabled={location?._id === activeLocation?._id}
                      value={availableForLocations.includes(location?._id)}
                      setValue={(v) => {
                        if (v) {
                          setAvailableForLocations((prev) => [...prev, location?._id]);
                        } else {
                          setAvailableForLocations((prev) => prev.filter((loc) => loc !== location?._id));
                        }
                      }}
                    />
                  </div>
                );
              }}
            />
          </div>
          <div className={styling("footer")}>
            <Button onClick={() => setShowModal(false)} theme="secondary" className={styling("btn")}>
              Cancel
            </Button>
            <Button
              type="submit"
              loading={isLoading}
              error={isError}
              theme="primary"
              className={styling("btn")}
              disabled={isLoading}
            >
              Save staff member
            </Button>
          </div>
        </FormV2>
      </div>
    </Modal>
  );
};
export default InternalStaffModal;