import { useState, useCallback, useMemo } from "react";
import { useFetch } from "shiftly-ui";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const useRating = ({ shifts, setOpen } = {}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const { activeLocation } = useShiftlyLocation();

  const { post } = useFetch({});

  const handleSave = useCallback(() => {
    const currentShift = shifts[activeStep];
    post([
      {
        entity: "Shift",
        method: "update",
        criteria: { _id: currentShift._id },
        data: { rated: true },
        options: {
          allowUpdate: true,
        },
      },
      {
        entity: "Rating",
        method: "create",
        data: {
          user: currentShift.user._id,
          shift: currentShift._id,
          comment: comments[currentShift._id] || "",
          rating: ratings[currentShift._id] || 0,
          logo: activeLocation?.logo,
        },
      },
    ]);

    if (activeStep + 1 === shifts.length) {
      setOpen(false);
    }
  }, [activeStep, ratings, shifts, post, comments, activeLocation, setOpen]);

  return {
    activeStep,
    setActiveStep,
    ratings,
    setRatings,
    comments,
    setComments,
    handleSave,
  };
};

export default useRating;