import { Each } from "shiftly-ui";
import styles from "./AccountSettings.module.css";
import StandardLayout from "./components/StandardLayout";
import { faHeadphones, faShuffle, faUserMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import StandardLayoutLink from "./components/StandardLayoutLink";
const links = [
  { icon: faUserMagnifyingGlass, text: "Your Details", link: "/account-settings/your-details" },
  {
    icon: faShuffle,
    text: "All Businesses",
    link: "/",
  },
];
const AccountSettings = () => {
  const navigate = useNavigate();
  return (
    <div className={styles["container"]}>
      <StandardLayout
        heading={
          <h1>
            Account <span>Settings</span>
          </h1>
        }
      >
        <div className={styles["links-container"]}>
          <Each of={links} render={({ key, ...link }) => <StandardLayoutLink key={key} {...link} />} />
        </div>
        <div className={styles["divider"]}></div>
        <div className={styles["support-container"]} onClick={() => navigate("/contact")}>
          <h2>We're here to help</h2>
          <div className={styles["support-link"]}>
            <div className={styles["link-details"]}>
              <div className={styles["link-icon"]}>
                <FontAwesomeIcon icon={faHeadphones} />
              </div>
              <div className={styles["link-details-text"]}>
                <h4>Contact Specialised Support</h4>
                <p>As a new host, you get one-tap access to a specially trained support team</p>
              </div>
            </div>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
        </div>
        <div className={styles["lose-account-container"]}>
          <p>
            Is there anything we can do better? We'd love your advice. Contact <span>team@shiftly.au</span> to get in
            touch with a team member.
          </p>
          <p className={styles["delete-account"]}>Deactivate Account</p>
        </div>
      </StandardLayout>
    </div>
  );
};
export default AccountSettings;