import {
  Input,
  Button,
  FileUploader,
  useFetch,
  TextArea,
  useToast,
  Dropdown,
  Address,
  FormFlex,
  FormV2,
} from "shiftly-ui";
import { useLocation } from "react-router-dom";
import StandardLayout from "../../settings/components/StandardLayout";
import styles from "./BusinessAccountLocationEdit.module.css";
import { faSave, faStar } from "@fortawesome/pro-regular-svg-icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoImage from "shiftly-ui/assets/png/no_img_placeholder.jpeg";
import { faFileUpload } from "@fortawesome/pro-solid-svg-icons";
import DraggableList from "src/components/DraggableList";
import useBusiness from "src/hooks/useBusiness";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const BusinessAccountLocationEdit = () => {
  const { activeBusiness } = useBusiness();
  const { refreshAvailableLocations } = useShiftlyLocation();
  const [updatedLocation, setUpdatedLocation] = useState({});
  const [uploading, setUploading] = useState("");
  const [images, setImages] = useState([]);
  const toast = useToast();
  const {
    post: saveLocation,
    isLoading,
    isError,
  } = useFetch({
    options: {
      onSuccess: () => {
        refreshAvailableLocations();
      },
    },
  });
  const { state } = useLocation();
  const {
    data: [location],
  } = useFetch({
    request: {
      entity: "Location",
      method: "get",
      populate: ["address"],
      criteria: {
        _id: state?.location?._id,
      },
    },
    dependency: state?.location?._id,
    options: {
      onSuccess: ([data = {}]) => {
        setUpdatedLocation({ ...data.address, ...data });
      },
    },
  });
  useEffect(() => {
    if (!location) return;
    setImages(location?.images?.map((img) => ({ key: img, content: <img src={img} alt="Location" /> })) || []);
  }, [location]);
  useEffect(() => {
    setUpdatedLocation((prev) => ({ ...prev, images: images.map((img) => img.key) }));
  }, [images]);
  const uploaderRef = useRef(null);
  const handleFileUploaded = useCallback(
    (url = []) => {
      if (!url?.length) return;
      if (uploading === "images") {
        setImages((prev) => [...prev, { key: url[0], content: <img src={url[0]} alt="Location" /> }]);
      } else {
        setUpdatedLocation((prev) => ({ ...prev, [uploading]: url[0] }));
      }
    },
    [uploading]
  );
  const handleSave = useCallback(
    async (data) => {
      if (images.length < 1) {
        toast.error("Please upload at least one image.");
        return;
      }
      return await saveLocation({
        entity: "Location",
        method: "updateLocationProfile",
        data: { ...data, business: activeBusiness?._id },
      });
    },
    [toast, saveLocation, activeBusiness, images]
  );
  return (
    <StandardLayout
      heading={<h1>{location?.name}</h1>}
      breadcrumb={"Business Settings / Locations /"}
      returnLink={"/business-account/locations"}
      buttonText={isLoading ? "Saving..." : "Save Changes"}
      buttonEvent={handleSave}
      buttonProps={{ icon: faSave, disabled: isLoading }}
    >
      <FileUploader
        multiple={false}
        visible={false}
        ref={uploaderRef}
        onUploadComplete={handleFileUploaded}
        accepts={"image/*"}
      />
      <FormV2 data={updatedLocation} setData={setUpdatedLocation} onSubmit={handleSave}>
        <div className={styles["heading"]}>
          <Input
            label={"Location Name"}
            placeholder={"Cozy Cafe Around the Corner"}
            name={"name"}
            placeholer={"Cozy Cafe Around the Corner"}
            required
          />
          {location?.rating && (
            <div className={styles["rating-container"]}>
              <h4>You're rated {location?.rating}</h4> <FontAwesomeIcon icon={faStar} />
            </div>
          )}
        </div>
        <div className={styles["logo-section"]}>
          <div className={styles["primary-logo"]}>
            <h4>Primary Logo</h4>
            <p>
              <span>Please note:</span> the box below is how your logo will be horizontally displayed for applicants.
            </p>
            <div
              className={styles["primary-image"]}
              onClick={() => {
                setUploading("logo");
                uploaderRef.current?.click();
              }}
            >
              <img src={updatedLocation?.logo || NoImage} alt="Primary logo" />
            </div>
            <div className={styles["upload-btn-container"]}>
              <Button
                icon={faFileUpload}
                iconSide="left"
                onClick={() => {
                  setUploading("logo");
                  uploaderRef.current?.click();
                }}
                className={styles["upload"]}
              >
                Upload File
              </Button>
            </div>
          </div>
          <div className={styles["icon"]}>
            <h4>Icon</h4>
            <p>
              <span>Please note:</span> the box below is how your icon will be viewed by applicants.
            </p>
            <div
              className={styles["icon-image"]}
              onClick={() => {
                setUploading("logo");
                uploaderRef.current?.click();
              }}
            >
              <img src={updatedLocation?.icon || NoImage} alt="Primary logo" />
            </div>
            <div className={styles["upload-btn-container"]}>
              <Button
                icon={faFileUpload}
                iconSide="left"
                onClick={() => {
                  setUploading("icon");
                  uploaderRef.current?.click();
                }}
                className={styles["upload"]}
              >
                Upload File
              </Button>
            </div>
          </div>
        </div>
        <div className={styles["description"]}>
          <TextArea
            name={"description"}
            label={"Description"}
            required
            placeholder={"Tell us about your location..."}
            rows={7}
          />
        </div>
        <FormFlex>
          <Input name={"instagram"} label={"Instagram username"} placeholder={"shiftly.au"} />
          <Input name={"facebook"} label={"Facebook page"} placeholder={"shiftly.au"} />
          <Input name={"website"} label={"Website URL"} placeholder={"https://www.shiftly.au/"} />
        </FormFlex>
        <div className={styles["address"]}>
          <Address name={"address"} />
        </div>
        <Dropdown mt={"40px"} name={"timezone"} />
        <div className={styles["images"]}>
          <div className={styles["images-heading"]}>
            <h5>Your Images:</h5>
            <Button
              icon={faFileUpload}
              iconSide="left"
              onClick={() => {
                setUploading("images");
                uploaderRef.current?.click();
              }}
              className={styles["upload-images"]}
            >
              Upload Images
            </Button>
          </div>
          <div className={styles["images-dnd-container"]}>
            {images?.length > 0 ? (
              <DraggableList
                items={images}
                setItems={setImages}
                layout="horizontal"
                draggableContainerClass={styles["draggable-image-container"]}
                dropBoxContainerClass={styles["images-drag-box"]}
              />
            ) : (
              <div className={styles["no-images"]}>
                <p>
                  You haven't uploaded any images.
                  <br />
                  Let's add some personality by clicking the upload button.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={styles["save-btn"]}>
          <Button
            className={styles["btn"]}
            loading={isLoading}
            type={"submit"}
            error={isError}
            theme={"primary"}
            icon={faSave}
          >
            Save Changes
          </Button>
        </div>
      </FormV2>
    </StandardLayout>
  );
};
export default BusinessAccountLocationEdit;
