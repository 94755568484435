import { Input } from "shiftly-ui";
import styles from "./BusinessAccount.module.css";
import useBusiness from "src/hooks/useBusiness";
import StandardLayout from "../../settings/components/StandardLayout";
const BusinessAccountBusinessDetails = () => {
  const { activeBusiness } = useBusiness();
  return (
    <StandardLayout
      heading={
        <h1>
          <span>Business</span> Details
        </h1>
      }
      breadcrumb={"Business Settings /"}
      returnLink={"/business-account"}
    >
      <div className={styles["home-container"]}>
        <div className={styles["home-content"]}>
          <div className={styles["home-content-block"]}>
            <Input label={"Business Name"} value={activeBusiness?.name} disabled />
            <Input label={"Australian Business Number (ABN)"} value={activeBusiness?.ABN} disabled />
            <Input
              label={"Business Award"}
              value={activeBusiness?.industry?.name + " (" + activeBusiness?.industry?.award_code + ")"}
              disabled
            />
          </div>
        </div>
      </div>
    </StandardLayout>
  );
};
export default BusinessAccountBusinessDetails;