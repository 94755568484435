import { useAuth, useFetch, Input, Button, FileUploader, useProfile, FormV2 } from "shiftly-ui";
import { useCallback, useRef } from "react";
import styles from "./AccountSettingsYourDetails.module.css";
import StandardLayout from "./components/StandardLayout";
import { faLock, faSave, faUpload } from "@fortawesome/pro-regular-svg-icons";
import noImage from "shiftly-ui/assets/png/no_img_placeholder.jpeg";
import StandardLayoutLink from "./components/StandardLayoutLink";
const AccountSettingsYourDetails = () => {
  const { user } = useAuth();
  const uploaderRef = useRef();
  const {
    post: updateDetails,
    isLoading,
    isError,
    refresh,
  } = useFetch({
    options: {
      onSuccess: (data) => {
        refresh("Profile.GetUserProfile");
        refresh("initAuth");
      },
    },
  });
  const { profile, updateProfile } = useProfile();
  const onProfilePicUpload = (url) => {
    if (!url[0]) return;
    updateProfile({
      data: {
        profile_picture: url[0],
      },
    });
  };
  const handleSave = useCallback(
    async ({ first_name, last_name, email, phone_number }) => {
      return await updateDetails({
        entity: "User",
        method: "update",
        criteria: {
          _id: user?._id,
        },
        data: {
          first_name,
          last_name,
          email,
          phone_number,
        },
      });
    },
    [user, updateDetails]
  );
  return (
    <StandardLayout
      heading={
        <h1>
          <span>Your</span> Details
        </h1>
      }
      breadcrumb={"Account Settings /"}
      returnLink={"/account-settings"}
    >
      <FileUploader ref={uploaderRef} visible={false} onUploadComplete={onProfilePicUpload} />
      <FormV2
        initial={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          phone_number: user.phone_number,
        }}
        onSubmit={handleSave}
      >
        <div className={styles["container"]}>
          <div className={styles["profile-container"]}>
            <h4>Profile Photo</h4>
            <p>
              <span>Please note:</span> Please ensure your full face is displayed centrally within the circle below:
            </p>
            <div className={styles["profile-photo-container"]} onClick={() => uploaderRef?.current?.click()}>
              <img src={profile?.profile_picture || noImage} alt="profile" />
            </div>
            <div className={styles["upload-container"]}>
              <Button
                onClick={() => uploaderRef.current?.click()}
                className={styles["upload-btn"]}
                icon={faUpload}
                iconSide="left"
              >
                Upload
              </Button>
            </div>
          </div>
          <div className={styles["details-container"]}>
            <Input name="first_name" />
            <Input name="last_name" />
            <Input name="email" />
            <Input name="phone_number" />
          </div>
        </div>
        <StandardLayoutLink
          icon={faLock}
          text={"Manage Your Password"}
          link={"/account-settings/your-details/manage-password"}
        />
        <div className={styles["save-container"]}>
          <Button
            type="submit"
            icon={faSave}
            theme={"secondary"}
            loading={isLoading}
            error={isError}
            className={styles["save-btn"]}
          >
            Save
          </Button>
        </div>
      </FormV2>
    </StandardLayout>
  );
};
export default AccountSettingsYourDetails;