import { Input, validatePassword, Button, useFetch, useToast, useAuth } from "shiftly-ui";
import styles from "./AccountSettingsPassword.module.css";
import StandardLayout from "./components/StandardLayout";
import { useCallback, useState } from "react";
import { faSave } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

const AccountSettingsPassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { user } = useAuth();
  const toast = useToast();

  const { isLoading, post: savePasswordPost } = useFetch({
    options: {
      onSuccess: () => {
        toast.success("Your password has been updated successfully!", "Success");
        navigate("/account-settings/your-details");
      },
    },
  });

  const handleSavePassword = useCallback(() => {
    if (newPassword !== confirmPassword) {
      toast.error("Your passwords don't match. Give it another whirl?", "Error");
      return;
    }
    if (!validatePassword(newPassword)) {
      toast.error("Ah this puzzle seems too easy to crack! Please make it more secure.", "Error");
      return;
    }
    savePasswordPost({
      entity: "Authentication",
      method: "changePassword",
      criteria: {
        user: user?._id,
      },
      data: {
        currentPassword,
        newPassword,
      },
    });
  }, [toast, newPassword, confirmPassword, savePasswordPost, currentPassword, user]);


  return (
    <StandardLayout
      heading={
        <h1>
          Manage <span>Password</span>
        </h1>
      }
      returnLink={"/account-settings/your-details"}
      breadcrumb={"Account Settings / Your Details /"}
    >
      <div className={styles["current-container"]}>
        <Input value={currentPassword} setValue={setCurrentPassword} name={"password"} validate={false} />
      </div>
      <div className={styles["new-container"]}>
        <h3>Create New Password</h3>
        <Input name={"password"} value={newPassword} setValue={setNewPassword} />
        <Input name={"confirm_password"} value={confirmPassword} setValue={setConfirmPassword} />
      </div>
      <div className={styles["save-button-container"]}>
        <Button
          onClick={handleSavePassword}
          disabled={isLoading}
          icon={faSave}
          theme={"secondary"}
          className={styles["save-button"]}
        >
          Save Password
        </Button>
      </div>
    </StandardLayout>
  );
};
export default AccountSettingsPassword;