import { useToast, useFetch } from "shiftly-ui";
import StandardLayout from "src/pages/settings/components/StandardLayout";
import IntegrationStep from "./IntegrationStep";
import { faPlug, faSpinner, faStore, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLayoutEffect, useMemo, useState } from "react";
import IntegrationMap from "./IntegrationMap";
import NewPositionModal from "src/pages/app/positions/components/NewPositionModal";
import { useNavigate } from "react-router-dom";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
import usePositions from "src/hooks/usePositions";
const IntegrationBase = ({
  name = "",
  type = "",
  description = "",
  connectionLink = "",
  activeIntegration,
  thirdPartyLocations = [],
  thirdPartyPositions = [],
  isLoading,
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const { allLocations, setActiveLocation } = useShiftlyLocation();
  const [mappedLocations, setMappedLocations] = useState({});
  const [mappedPositions, setMappedPositions] = useState({});
  const [initial, setInitial] = useState({
    description: "",
    group: "",
    position_rate_modifier: 0,
    name: "",
    is_active: true,
  });
  const [newPositionModal, setNewPositionModal] = useState(false);
  const { allPositions } = usePositions();
  const navigate = useNavigate();
  const toast = useToast();
  const { post: updateIntegration, refresh } = useFetch({
    options: {
      onSuccess: (data) => {
        refresh("Integration.GetActiveIntegrationByType");
        refresh("Integration.GetAllIntegrations");
      },
    },
  });
  const locationsComplete = useMemo(() => {
    const length = Object.keys(mappedLocations).length;
    return length === thirdPartyLocations.length && length > 0;
  }, [mappedLocations, thirdPartyLocations]);
  const positionsComplete = useMemo(() => {
    const length = Object.keys(mappedPositions).length;
    return length === thirdPartyPositions.length && length > 0;
  }, [mappedPositions, thirdPartyPositions]);
  useLayoutEffect(() => {
    if (activeIntegration) {
      setMappedLocations(activeIntegration?.locationMap || {});
      setMappedPositions(activeIntegration?.positionMap || {});
    }
  }, [activeIntegration]);
  useLayoutEffect(() => {
    if (activeIntegration && !activeIntegration?.locationMap) {
      setActiveStep(2);
    } else if (activeIntegration && !activeIntegration?.positionMap) {
      setActiveStep(3);
    }
  }, [activeIntegration]);
  return (
    <>
      <NewPositionModal
        setNewPositionModal={setNewPositionModal}
        newPositionModal={newPositionModal}
        initial={initial}
        setInitial={setInitial}
      />
      <StandardLayout
        heading={<h1>{name} Integration</h1>}
        description={description}
        breadcrumb={"Business Profile / Integrations /"}
        returnLink={"/integrations"}
        size={"medium"}
      >
        <IntegrationStep
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          status={activeIntegration ? "complete" : "open"}
          number={1}
          name={"Connect to " + name}
          icon={faPlug}
          buttonText={isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : "Connect"}
          buttonAction={() => window.open(connectionLink, "_self")}
          description={`To connect your ${name} account with Shiftly, you'll need to authenticate via OAuth. When you click on the Connect button, you'll be redirected to the ${name} login page. Here, you'll log in with your ${name} credentials and authorise Shiftly to access your ${name} account. Once authorised, you'll be redirected back to Shiftly, where your ${name} account will be securely connected. `}
        ></IntegrationStep>
        <IntegrationStep
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          status={locationsComplete && activeStep !== 2 ? "complete" : activeStep === 2 ? "open" : "incomplete"}
          number={2}
          name={"Map Locations"}
          icon={faStore}
          buttonText="Next"
          buttonAction={() => {
            updateIntegration({
              entity: "Integration",
              method: "update",
              criteria: { _id: activeIntegration?._id },
              data: {
                locationMap: mappedLocations,
              },
            });
            setTimeout(() => {
              setActiveStep(3);
            }, 200);
          }}
          disabled={!locationsComplete}
          description={`In this section, you'll need to map ${name} locations to their corresponding Shiftly locations. If a Shiftly location doesn't exist yet, please create it before completing the mapping.`}
        >
          <IntegrationMap
            toLink={thirdPartyLocations}
            options={allLocations}
            mode={"locations"}
            data={mappedLocations}
            setData={setMappedLocations}
            setActiveStep={setActiveStep}
            onCreate={() => navigate("/business-account/locations/edit-location")}
          />
        </IntegrationStep>
        <IntegrationStep
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          status={positionsComplete && activeStep !== 3 ? "complete" : activeStep === 3 ? "open" : "incomplete"}
          disabled={!positionsComplete}
          number={3}
          name={"Map Positions"}
          icon={faUser}
          buttonText="Complete"
          buttonAction={() => {
            updateIntegration({
              entity: "Integration",
              method: "update",
              criteria: { _id: activeIntegration?._id },
              data: {
                positionMap: mappedPositions,
                status: "active",
              },
            });
            navigate("/integrations");
            toast.success("Integration successfully updated", "success");
          }}
          description={`In this section, you'll need to map ${name} ${
            type === "deputy" ? "Areas" : "positions"
          } to their corresponding Shiftly positions. If a Shiftly position doesn't exist yet, please create it before completing the mapping.`}
        >
          <IntegrationMap
            toLink={thirdPartyPositions}
            options={allPositions}
            mode={"positions"}
            data={mappedPositions}
            setData={setMappedPositions}
            onCreate={(data) => {
              setActiveLocation(data);
              setNewPositionModal(true);
            }}
          />
        </IntegrationStep>
      </StandardLayout>
    </>
  );
};
export default IntegrationBase;