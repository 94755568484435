import { RadioButtons, useStyling } from "shiftly-ui";
import styles from "./HQNewBusinessSetup.module.css";
import HQMultipleCard from "./HQMultipleCard";
import { faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";
const HQMultipleLocations = ({ setActiveStep }) => {
  const styling = useStyling(styles);
  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h1>
          Do you have <span>multiple locations</span>?
        </h1>
      </div>
      <div className={styling("content", "short-content")}>
        <RadioButtons
          required
          align="center"
          name={"multipleLocations"}
          options={[
            {
              label: "Yes",
              value: "yes",
              content: (
                <HQMultipleCard
                  icon={faCheck}
                  text={"Yes"}
                  subtext={"We have more than one location."}
                  onClick={() => {
                    setActiveStep(3);
                  }}
                />
              ),
            },
            {
              label: "No",
              value: "no",
              content: (
                <HQMultipleCard
                  icon={faTimes}
                  text={"No"}
                  subtext={"We only have one location."}
                  onClick={() => {
                    setTimeout(() => {
                      setActiveStep(3);
                    }, 300);
                  }}
                />
              ),
            },
          ]}
          type="cards"
        />
      </div>
    </div>
  );
};
export default HQMultipleLocations;