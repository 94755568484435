import { withDisplay, useGestures } from "shiftly-ui";
import styles from "./BusinessWrapper.module.css";
import { useEffect } from "react";
import useBusiness from "src/hooks/useBusiness";
import MobileLocationSelector from "./MobileLocationSelector";
import TaskList from "./task_list/TaskList";
import { useLocation } from "react-router-dom";
import useAI from "src/hooks/useAI";
import { instructions, welcomeMessage } from "src/utility/ai";
import { CopilotPopup } from "@copilotkit/react-ui";
import FloatingButton from "./ai/FloatingButton";
import Header from "./ai/Header";
import RateShifter from "src/pages/app/misc/ratings/RateShifter";
const BusinessWrapper = ({ children, isMobile }) => {
  const { initBusiness } = useBusiness();
  const { pathname } = useLocation();
  const { bind } = useGestures();
  useAI();
  useEffect(() => {
    initBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    pathname !== "/portal" && (
      <div className={styles["container"]} {...bind()}>
        <CopilotPopup
          labels={{
            title: "Shiftly Assistant",
            initial: welcomeMessage,
          }}
          Button={FloatingButton}
          Header={Header}
          showResponseButton={false}
          instructions={instructions}
        />
        <RateShifter />
        {pathname !== "/" && isMobile && <MobileLocationSelector />}
        {!isMobile && <TaskList />}
        <div className={styles["content"]}>{children}</div>
      </div>
    )
  );
};
export default withDisplay(BusinessWrapper);
