import useBusiness from "src/hooks/useBusiness";
import styles from "./BusinessAccount.module.css";
import { faCreditCard, faFileInvoice, faList, faMapPin, faPlug } from "@fortawesome/pro-regular-svg-icons";
import StandardLayout from "../../settings/components/StandardLayout";
import StandardLayoutLink from "../../settings/components/StandardLayoutLink";
const BusinessAccountHome = () => {
  const { activeBusiness } = useBusiness();
  return (
    <StandardLayout
      heading={
        <h1>
          <span style={{ marginRight: "15px" }}>{activeBusiness?.name}</span> Settings
        </h1>
      }
    >
      <div className={styles["home-content"]}>
        <div className={styles["home-content-block"]}>
          <div className={styles["home-content-heading"]}>
            <h3>Business</h3>
          </div>
          <div className={styles["home-content-body"]}>
            <StandardLayoutLink icon={faList} text={"Business Details"} link={"/business-account/business-details"} />
            <StandardLayoutLink icon={faMapPin} text={"Locations"} link={"/business-account/locations"} />
            <StandardLayoutLink icon={faPlug} text={"Integrations"} link={"/integrations"} />
          </div>
        </div>
        <div className={styles["home-content-block"]}>
          <div className={styles["home-content-heading"]}>
            <h3>Payment</h3>
          </div>
          <div className={styles["home-content-body"]}>
            <StandardLayoutLink
              icon={faCreditCard}
              text={"Billing & Payment"}
              link={"/business-account/billing-and-payment"}
            />
            <StandardLayoutLink
              icon={faFileInvoice}
              text={"Payment History"}
              link={"/business-account/billing-and-payment/payment-history"}
            />
          </div>
        </div>
      </div>
    </StandardLayout>
  );
};
export default BusinessAccountHome;