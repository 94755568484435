import { useStyling, BigToggle, Button, useFetch, useAlerts, useToast, useSearchParams, newGUID, withDisplay } from "shiftly-ui";
import { useCallback, useMemo, useState } from "react";
import styles from "./People.module.css";
import SearchAndDisplay from "src/components/SearchAndDisplay";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import ShiftlyApplicants from "./components/ShiftlyApplicants";
import InternalStaffModal from "./components/InternallStaffModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/pro-regular-svg-icons";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  locations: [],
  business: "",
};
const People = ({ isMobile }) => {
  const [tab, setTab] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [initial, setInitial] = useState(initialState);
  const { activeLocation } = useShiftlyLocation();
  const styling = useStyling(styles);
  const { data: internalStaff } = useFetch({
    request: {
      entity: "InternalStaff",
      method: "get",
      id: "User.GetInternalStaff",
      criteria: {
        locations: { $elemMatch: { $eq: activeLocation?._id } },
      },
    },
    dependency: activeLocation?._id,
  });
  useSearchParams(
    ["tab"],
    ({ tab }) => {
      switch (tab) {
        case "shifters":
          setTab(0);
          break;
        case "internal":
          setTab(0);
          break;
        default:
          setTab(1);
          break;
      }
    },
    []
  );
  const elements = useMemo(() => {
    return internalStaff?.map((staff) => ({
      original: { ...staff, name: `${staff.first_name} ${staff.last_name}` },
      listContent: <InternallStaffList staff={staff} setInitial={setInitial} setShowModal={setShowModal} />,
    }));
  }, [internalStaff]);
  return (
    <>
      <InternalStaffModal showModal={showModal} setShowModal={setShowModal} initial={initial} setInitial={setInitial} />
      <div className={styling("container")}>
        {!isMobile && (
          <BigToggle tab={tab} setTab={setTab} tabs={[{ text: "Internal Staff" }, { text: "Shifters" }]} align="left">
            {tab === 0 && (
              <div className={styling("create-btn")}>
                <Button
                  theme={"secondary"}
                  icon={faPlus}
                  onClick={() => {
                    setInitial({ ...initialState, business: newGUID() });
                    setShowModal(true);
                  }}
                >
                  Add a staff member
                </Button>
              </div>
            )}
          </BigToggle>
        )}
        {tab === 0 && (
          <div className={styling("internal-staff")}>
            <SearchAndDisplay
              elements={elements}
              emptyStateText={
                <p>
                  You don't have any internal staff members yet.
                  <br />
                  Add a staff member to get started.
                </p>
              }
              defaultView={"list"}
              hideToggle
              elementsName="Staff"
              searchFields={["name", "email"]}
            />
          </div>
        )}
        {tab === 1 && <ShiftlyApplicants />}
      </div>
    </>
  );
};
const InternallStaffList = ({ staff, setInitial, setShowModal }) => {
  const styling = useStyling(styles);
  const toast = useToast();
  const { confirm } = useAlerts();
  const { post: deleteStaff, refresh } = useFetch({
    options: {
      onSuccess: () => {
        toast.success("Staff member deleted successfully");
        refresh("User.GetInternalStaff");
      },
    },
  });
  const handleDelete = useCallback(async () => {
    if (
      !(await confirm({
        label: "Are you sure you want to delete this staff member?",
        text: (
          <p>
            Deleting a staff member cannot be undone.
            <br />
            <br />
            Shifts currently associated with this staff member will continue as normal, however you will no longer be
            able to create shifts for this staff member.
          </p>
        ),
        inverse: true,
        confirmText: "Delete",
      }))
    )
      return;
    deleteStaff({
      entity: "InternalStaff",
      method: "delete",
      criteria: { _id: staff._id },
    });
  }, [confirm, deleteStaff, staff]);
  return (
    <div
      className={styling("row-container")}
      onClick={() => {
        setInitial(staff);
        setShowModal(true);
      }}
    >
      <div className={styling("row-section")}>
        <p>
          {staff?.first_name} {staff?.last_name}
        </p>
      </div>
      <div className={styling("row-section")}>
        <p>{staff?.email}</p>
      </div>
      <div className={styling("row-section", "actions")}>
        <div className={styling("action")}>
          <FontAwesomeIcon icon={faPencil} />
        </div>
        <div
          className={styling("action", "trash")}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete();
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>
      </div>
    </div>
  );
};
export default withDisplay(People);