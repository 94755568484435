import { Input, useAuth, useToast, useFetch } from "shiftly-ui";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import styles from "./BusinessAccountAddNewPaymentMethodForm.module.css";
import {
  AuBankAccountElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import StripeWrapper from "src/components/StripeWrapper";
import useBusiness from "src/hooks/useBusiness";
import useShiftlyLocation from "src/hooks/useShiftlyLocation";
const stripeStyle = {
  style: {
    base: {
      color: "black",
      fontSize: "18px",
      iconColor: "#bebebe",
      "::placeholder": {
        color: "#bebebe",
        fontWeight: "300",
      },
    },
  },
};
const BusinessAccountAddNewPaymentMethodForm = forwardRef(
  ({ tab, clientSecret = "", setShowModal, setLoading }, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useAuth();
    const toast = useToast();
    const { activeBusiness } = useBusiness();
    const { refreshAvailableLocations } = useShiftlyLocation();
    const [setupIntent, setSetupIntent] = useState(null);
    const { post: createPaymentMethod, refresh } = useFetch({
      options: {
        onSuccess: () => {
          toast.success("Payment method added successfully");
          refreshAvailableLocations();
          refresh("PaymentMethod.GetPaymentMethods");
          refresh("PaymentMethod.CreateSetupIntent");
          setShowModal(false);
          setLoading(false);
          setSetupIntent(null);
        },
        onError: () => {
          setLoading(false);
        },
      },
    });
    const handleRedirects = useCallback(
      async (setupIntent) => {
        // Check if the setup intent requires an action (e.g., 3D Secure)
        if (setupIntent.status === "requires_action" || setupIntent.status === "requires_confirmation") {
          const { error, setupIntent: confirmedSetupIntent } = await stripe.handleCardAction(setupIntent.client_secret);
          if (error) {
            throw new Error(error.message);
          }
          return confirmedSetupIntent.payment_method;
        }
        return setupIntent.payment_method;
      },
      [stripe]
    );
    const onSubmit = useCallback(
      async (data) => {
        setLoading(true);
        try {
          let paymentMethod;
          //Card
          if (tab === 1) {
            const cardElement = elements.getElement(CardNumberElement);
            if (!cardElement) {
              throw new Error("Card number is invalid. Please check and try again");
            }
            let activeSetupIntent = setupIntent;
            if (!activeSetupIntent) {
              const { setupIntent: newSetupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                  card: cardElement,
                  billing_details: {
                    name: data.first_name + " " + data.last_name,
                    email: user?.email,
                    address: {
                      line1: data.street,
                      city: data.city,
                      state: data.state?.value,
                      postal_code: data.postcode,
                    },
                  },
                },
                return_url: window.location.href,
              });
              if (error) {
                throw new Error(error.message);
              }
              activeSetupIntent = newSetupIntent;
              setSetupIntent(newSetupIntent);
            }
            paymentMethod = await handleRedirects(activeSetupIntent);
            cardElement.clear();
          }
          //Bank
          else {
            const bankElement = elements.getElement(AuBankAccountElement);
            if (!bankElement) {
              throw new Error("Bank details are invalid. Please check and try again");
            }
            let activeSetupIntent = setupIntent;
            if (!activeSetupIntent) {
              const { setupIntent: newSetupIntent, error } = await stripe.confirmAuBecsDebitSetup(clientSecret, {
                payment_method: {
                  au_becs_debit: bankElement,
                  billing_details: {
                    name: data.first_name + " " + data.last_name,
                    email: user?.email,
                    address: {
                      line1: data.street,
                      city: data.city,
                      state: data.state?.value,
                      postal_code: data.postcode,
                    },
                  },
                },
                return_url: window.location.href, // Add return_url here
              });
              if (error) {
                throw new Error(error.message);
              }
              activeSetupIntent = newSetupIntent;
              setSetupIntent(newSetupIntent);
            }
            paymentMethod = await handleRedirects(activeSetupIntent);
            bankElement.clear();
          }
          if (!paymentMethod) {
            throw new Error("A processing error occurred. Please refresh the page and try again.");
          }
          return await createPaymentMethod({
            entity: "PaymentMethod",
            method: "create",
            data: {
              ...data,
              paymentMethod,
              type: tab === 0 ? "bank_account" : "card",
              business: activeBusiness?._id,
            },
          });
        } catch (error) {
          setLoading(false);
          toast.error(error.message);
        }
      },
      [
        stripe,
        elements,
        tab,
        clientSecret,
        user,
        setLoading,
        createPaymentMethod,
        handleRedirects,
        activeBusiness,
        toast,
        setupIntent,
      ]
    );
    useImperativeHandle(ref, () => ({
      handleSubmit: onSubmit,
    }));
    return (
      <div className={styles["tabbed-content"]}>
        {tab === 0 ? (
          <div className={styles["account-details"]}>
            <Input name={"account_name"} required placeholder={"John Doe"} label={"Account Name"} />
            <StripeWrapper label={"Bank Details:"}>
              <AuBankAccountElement options={stripeStyle} />
            </StripeWrapper>
          </div>
        ) : (
          <div className={styles["card-details"]}>
            <div className={styles["card-number-container"]}>
              <StripeWrapper label={"Card Number:"}>
                <CardNumberElement options={stripeStyle} />
              </StripeWrapper>
            </div>
            <div className={styles["exp-cvv-container"]}>
              <div className={styles["expiry-container"]}>
                <StripeWrapper label={"Expiry Date:"}>
                  <div style={{ minWidth: "100px" }}>
                    <CardExpiryElement options={stripeStyle} />
                  </div>
                </StripeWrapper>
              </div>
              <StripeWrapper label={"CVC:"}>
                <div style={{ minWidth: "100px" }}>
                  <CardCvcElement options={stripeStyle} />
                </div>
              </StripeWrapper>
            </div>
          </div>
        )}
      </div>
    );
  }
);
export default BusinessAccountAddNewPaymentMethodForm;