import { Input, TextArea, FormFlex, useStyling, Dropdown } from "shiftly-ui";
import styles from "./NewPosition.module.css";
import usePositions from "src/hooks/usePositions";
import { useEffect, useMemo } from "react";
const textAreaPlaceholder = `Include a friendly description, and highlight some of the duties of the position.`;
const NewPositionDetails = ({ setData = () => {}, data = {} }) => {
  const styling = useStyling(styles);
  const { allGroups, dropdownPositions } = usePositions();
  const newGroups = useMemo(
    () =>
      allGroups
        .filter((g) => !dropdownPositions.map((g) => g.value).includes(g._id) || g._id === data.group)
        .map((group) => ({
          value: group._id,
          label: group.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [allGroups, dropdownPositions, data.group]
  );
  useEffect(() => {
    if (!data.group) return;
    const group = allGroups.find((g) => g._id === data.group);
    setData((prev) => ({
      ...prev,
      description: group?.description ?? textAreaPlaceholder,
      name: group?.name ?? "",
    }));
  }, [data?.group, allGroups, setData]);
  return (
    <div className={styling("container")}>
      <div className={styling("heading")}>
        <h1>
          We'll need some <span>details</span> about the position.
        </h1>
      </div>
      <div className={styling("content", "medium-content")}>
        <FormFlex>
          <Dropdown enableSearch name={"group"} label="Name" placeholder={"Barista"} options={newGroups} required />
          <Input label={"Nickname"} placeholder={"Coffee Wizard"} name={"name"} />
        </FormFlex>
        <TextArea label={"Description"} rows={5} placeholder={textAreaPlaceholder} name={"description"} required />
      </div>
    </div>
  );
};
export default NewPositionDetails;