import allShiftly from "shiftly-ui";


import { useUI, Authentication, useDisplay, useAuth, FunctionalWrapper } from "shiftly-ui";

import { Route, Routes, useLocation } from "react-router-dom";
import BusinessHome from "./pages/business/BusinessHome";
import PositionsHome from "./pages/app/positions/PositionsHome";
import LocationsHome from "./pages/business/business_account/BusinessAccountLocationsHome";
import MessagesHome from "./pages/app/messages/MessagesHome";
import {
  BusinessAccountHome,
  BusinessAccountBusinessDetails,
  BusinessAccountBillingAndPayment,
  BusinessAccountPaymentHistory,
} from "./pages/business";
import Main from "./components/Main";
import { AccountSettings, AccountSettingsYourDetails } from "./pages/settings";
import AccountSettingsPassword from "./pages/settings/AccountSettingsPassword";
import BusinessAccountLocationEdit from "./pages/business/business_account/BusinessAccountLocationEdit";
import Scheduler from "./pages/app/shifts/scheduler/Scheduler";
import BusinessWrapper from "./components/BusinessWrapper";
import MobileShifts from "./pages/app/shifts/mobile_shifts/MobileShifts";
import IntegrationsHome from "./pages/business/integrations/IntegrationsHome";
import Deputy from "./pages/business/integrations/pages/Deputy";
import PositionLevels from "./pages/app/positions/PositionLevels/PositionLevels";
import People from "./pages/app/people/People";
import MobileAccount from "./pages/settings/MobileAccount";
import { SchedulerContextProvider } from "src/contexts/Scheduler";
import useShiftlyLocation from "./hooks/useShiftlyLocation";
import Signup from "./pages/authentication/Signup";
import Login from "./pages/authentication/Login";
import ResetPassword from "./pages/authentication/ResetPassword";

const App = () => {
  const { mode } = useUI();
  const location = useLocation();
  const { isMobile } = useDisplay();
  const { user } = useAuth();
  const { activeLocation } = useShiftlyLocation();

  return (
    <FunctionalWrapper init_id={activeLocation?._id ?? user?._id}>
      <Main>
        <Routes location={location} key={location.pathname}>
          <Route
            path="/portal"
            exact
            element={<Authentication signup={<Signup />} login={<Login />} reset={<ResetPassword />} />}
          />
        </Routes>
        {mode === "owner" && (
          <BusinessWrapper>
            <Routes location={location} key={location.pathname}>
              <Route path="/" exact element={<BusinessHome />} />
              {/* Main Routes */}
              <Route
                path="/shifts"
                exact
                element={
                  <SchedulerContextProvider>
                    <Scheduler />
                  </SchedulerContextProvider>
                }
              />
              <Route path="/mobile-shifts" exact element={<MobileShifts />} />
              <Route path="/people" exact element={<People />} />
              <Route path="/positions" exact element={<PositionsHome />} />
              <Route path="/positions/position-levels/:group" exact element={<PositionLevels />} />
              <Route path="/messaging" exact element={<MessagesHome />} />
              {/* Business Account */}
              {isMobile ? (
                <Route path="/business-account/*" element={<MobileAccount />} />
              ) : (
                <>
                  <Route path="/business-account" exact element={<BusinessAccountHome />} />
                  <Route path="/business-account/business-details" exact element={<BusinessAccountBusinessDetails />} />
                  <Route path="/business-account/locations" exact element={<LocationsHome />} />
                  <Route
                    path="/business-account/locations/edit-location"
                    exact
                    element={<BusinessAccountLocationEdit />}
                  />
                  <Route
                    path="/business-account/billing-and-payment"
                    exact
                    element={<BusinessAccountBillingAndPayment />}
                  />
                  <Route
                    path="/business-account/billing-and-payment/payment-history"
                    exact
                    element={<BusinessAccountPaymentHistory />}
                  />
                </>
              )}
              {/* Integrations */}
              <Route path="/integrations" exact element={<IntegrationsHome />} />
              <Route path="/integrations/deputy" exact element={<Deputy />} />
              {/* Account */}
              <Route path="/account-settings" exact element={<AccountSettings />} />
              <Route path="/account-settings/your-details" exact element={<AccountSettingsYourDetails />} />
              <Route
                path="/account-settings/your-details/manage-password"
                exact
                element={<AccountSettingsPassword />}
              />
              {/* <Route path="/tncs" exact element={<TermsAndConditions />} /> */}
            </Routes>
          </BusinessWrapper>
        )}
      </Main>
    </FunctionalWrapper>
  );
};
export default App;